import { EventInfo } from "./../../models/Model.Event";

export interface EventState {
  events: EventInfo[];
  // loaded: boolean;
}

export enum EventActionTypes {
  EVENTS_FETCH = "@@event/EVENTS_FETCH",
  EVENTS_REQUEST = "@@event/EVENTS_REQUEST",
  EVENTS_SUCCESS = "@@event/EVENTS_SUCCESS",
  EVENTS_ERROR = "@@event/EVENTS_ERROR",
  EVENT_NOT_TODAY = "@@event/EVENT_NOT_TODAY",
}
