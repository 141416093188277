import React, { Component } from "react";
import { connect } from "react-redux";
import { actions, RootState } from "../../../store";
import { withRouter, RouteComponentProps } from "react-router";
import { IonButton } from "@ionic/react";
import "./../../Admin.scss";
import { log, LogLevel } from "../../../utils/LogUtil";
import { GlobalKey, getGlobal, setGlobal } from "../../../utils/GlobalUtil";
import { fetchAPI } from "../../../utils/APIUtil";
import { UIPopupType } from "../../../store/ui/types";
import { getDateStringFromToday } from "../../../utils/TimeUtil";
import { getOS } from "../../../utils/DeviceUtil";
import {
  BizJobOfferName,
  JobApplyInfo,
  JobApplyStatus,
  JobOfferInfo,
  JobOfferName,
  JobOfferSalaryType,
  JobOfferStatus,
  JobOfferStatusName,
} from "../../../models/Model.JobPost";
import DownloadUtil from "../../../utils/DownloadUtil";
import * as API from "../../../API.json";
import { Flex } from "../../../components/atoms/Layout";
import Button from "../../../components/atoms/Button";
import Textarea from "react-textarea-autosize";
import StringUtil from "../../../utils/StringUtil";
import Input from "../../../components/atoms/Input";
import copy from "copy-to-clipboard";

const ADMIN_API = {
  JOBPOST_OFFERS: {
    method: "get",
    path: "/admin/jobpost/offers",
    contentType: "application/json",
  },
};

type Props = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    onSelectOffer: (offer: JobOfferInfo) => void;
  };

type State = {
  items: any[];
  // dateField: string;
  // dateStart: string;
  // dateStartWarn: boolean;
  // dateEnd: string;
  // dateEndWarn: boolean;
  finished: boolean;
  loading: boolean;
  selectedIndex: number;
  orderBy: string;
  orderAsc: boolean;
  unitCount: number;
  search: string;
};

const MESSAGE_TEMPLATE_KEY = "strAdminJobpostBizMessageTemplate";
const os = getOS();

class JobpostCandidateList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let message = getGlobal(MESSAGE_TEMPLATE_KEY, true);

    this.state = {
      items: [],
      finished: false,
      loading: false,
      // dateField: "alive",
      // dateStart: getDateStringFromToday({ weeks: -1 }),
      // dateStartWarn: false,
      // dateEnd: getDateStringFromToday({ days: 1 }),
      // dateEndWarn: false,
      selectedIndex: -1,
      orderBy: "",
      orderAsc: false,
      unitCount: 200,
      search: "",
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  refresh = (item: JobOfferInfo = null) => {
    log(LogLevel.UI_EVENT, "JobpostCandidateList.refresh", item);
    if (item) {
      let items = [...this.state.items];
      let index = items.findIndex((i) => i.id === item.id);
      if (index >= 0) {
        items[index] = { ...items[index], ...item };
        this.setState({ items });
      } else {
        this.setState({ items: [item, ...items] });
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = () => {
    // if (this.state.dateStartWarn || this.state.dateEndWarn) return;

    this.setState({ loading: true, items: [] });
    fetchAPI(
      ADMIN_API.JOBPOST_OFFERS,
      "",
      {
        withEnded: 1,
        start: 0,
        count: this.state.unitCount,
        // startDate: this.state.dateStart,
        // endDate: this.state.dateEnd,
        // dateField: "this.state.dateField",
        ownerType: 2,
        auto: true,
      },
      null,
      getGlobal(GlobalKey.TOKEN)
    ).then((result) => {
      log(LogLevel.UI_EVENT, "JobpostList:fetchCompanyData Offer"), result;
      if (result && !result.error) {
        let items = result.data;
        if (this.state.orderBy) {
          items = this.orderJobOffer(
            items,
            this.state.orderBy,
            this.state.orderAsc
          );
        }
        items.map((item) => this.setOrderInfo(item));

        this.setState({
          selectedIndex: -1,
          items: result.data,
          finished: result.data.length < this.state.unitCount,
          loading: false,
          orderBy: "",
        });
      } else {
        this.setState({
          selectedIndex: -1,
          items: [],
          finished: true,
          loading: false,
        });
      }
    });
  };

  onDetailOffer = (index) => {
    this.setState({ selectedIndex: index });

    fetchAPI(
      API.JOBPOST_OFFER_GET,
      this.state.items[index].id,
      { chatlist: true },
      null,
      getGlobal(GlobalKey.TOKEN)
    ).then((result) => {
      log(LogLevel.UI_EVENT, "JobpostList:fetchDataMore Offer", result);
      if (result && !result.error) {
        let items = [...this.state.items];
        items[index] = { ...items[index], ...result.data };
        this.setState({ items });
      }
    });
  };

  fetchDataMore = async () => {
    if (this.state.loading == true) return;
    this.setState({ loading: true });
    fetchAPI(
      ADMIN_API.JOBPOST_OFFERS,
      "",
      {
        withEnded: 1,
        start: this.state.items.length,
        count: this.state.unitCount,
        // startDate: this.state.dateStart,
        // endDate: this.state.dateEnd,
        // dateField: "this.state.dateField",
        ownerType: 2,
        auto: true,
      },
      null,
      getGlobal(GlobalKey.TOKEN)
    ).then((result) => {
      log(LogLevel.UI_EVENT, "JobpostList:fetchDataMore Offer", result);
      if (result && !result.error) {
        let items = [...this.state.items, ...result.data];
        if (this.state.orderBy) {
          items = this.orderJobOffer(
            items,
            this.state.orderBy,
            this.state.orderAsc
          );
        }
        items.map((item) => this.setOrderInfo(item));
        this.setState({
          items,
          finished: result.data.length < this.state.unitCount,
          loading: false,
        });
      } else {
        this.setState({ finished: true, loading: false });
      }
    });
  };

  onSetOrderJobOffer(field: string) {
    let asc: boolean = this.state.orderAsc;

    if (this.state.orderBy == field) {
      asc = !asc;
    }
    let items = [...this.state.items];
    items = this.orderJobOffer(items, field, asc);
    this.setState({ items, orderBy: field, orderAsc: asc });
  }

  orderJobOffer(data: any[], field: string, asc: boolean) {
    data = data.sort((a: JobOfferInfo, b: JobOfferInfo) => {
      let tokens = field.split(".");
      let aCmp = 0,
        bCmp = 0;
      if (tokens.length == 1) {
        aCmp = a[tokens[0]];
        bCmp = b[tokens[0]];
      } else if (tokens.length == 2) {
        aCmp = a[tokens[0]][tokens[1]];
        bCmp = b[tokens[0]][tokens[1]];
      }

      let rvalue = 0;
      if (aCmp > bCmp) rvalue = -1;
      else if (aCmp < bCmp) rvalue = 1;

      if (!asc) rvalue *= -1;

      return rvalue;
    });
    return data;
  }

  onDateChange = (e, field) => {
    let dateString = e.target.value;
    let dateWarn = false;
    try {
      let date = Date.parse(dateString);
      if (!date) {
        log(
          LogLevel.UI_EXCEPTION,
          "Admin:JobpostList:onDateEndChange",
          dateString
        );
        dateWarn = true;
      } else {
        log(
          LogLevel.UI_DATA_LOAD,
          "Admin:JobpostList:onDateEndChange",
          dateString,
          date
        );
      }
    } catch (err) {
      log(
        LogLevel.UI_EXCEPTION,
        "Admin:JobpostList:onDateEndChange",
        dateString,
        err
      );
      dateWarn = true;
    }
    let newState: any = {};
    (newState[field] = dateString),
      (newState[field + "Warn"] = dateWarn),
      this.setState(newState);
  };

  render() {
    log(LogLevel.UI_LIFECYCLE, "JobpostList.render", this.props, this.state);

    let more;
    if (!this.state.finished) {
      more = (
        <IonButton expand="full" onClick={this.fetchDataMore}>
          더 불러오기
        </IonButton>
      );
    }

    let list = this.renderOfferList();

    return (
      <div className="admin-full-container">
        <Flex alignItems="center">
          <IonButton onClick={this.fetchData}>불러오기</IonButton>
          <div className="common-flex-grow" />
          <Input
            value={this.state.search}
            onChange={(e) => this.setState({ search: e.target.value })}
            placeholder="검색어"
            onClear={() => this.setState({ search: "" })}
          />
        </Flex>

        <div className="common-container">
          {more}
          {list}
          {more}
        </div>
        <div style={{ height: "100px" }} />
      </div>
    );
  }

  getOfferTerm(offer: JobOfferInfo) {
    return JobOfferStatusName[offer.status];
    // if (offer.status == JobOfferStatus.POSTED) return "공고중";
    // else if (offer.status == JobOfferStatus.ENDED) {
    //   return "공고종료";
    // }
    // return "";
  }

  setOrderInfo = (offer: JobOfferInfo) => {
    if (offer.order && offer.order.length > 0) {
      for (let i = 0; i < offer.order.length; i++) {
        if (offer.order[i].type == 0) {
          offer.orderName = offer.order[i].title;
        }
        if (offer.order[i].type == 2) {
          offer.orderCoupon = offer.order[i].code;
          offer.orderCouponPrice = -offer.order[i].price;
        }
      }
    }
    if (!offer.orderPayAmount) offer.orderPayAmount = 0;
  };

  onCall = (phoneNumer: string) => (e) => {
    e.stopPropagation();
    window.open(`tel:${phoneNumer}`, "_system");
  };

  onEmail = (email: string, item: JobOfferInfo) => (e) => {
    e.stopPropagation();
    copy(email);
    this.props.toastPopup.show("클립보드에 email이 저장되었습니다.");
  };

  renderOfferList = () => {
    return (
      <table id="admin-table" className="admin-table">
        <thead className="admin-table-fixed-header">
          <tr>
            <td
              className="admin-table-label-x"
              onClick={() => this.onSetOrderJobOffer("id")}
            >
              ID
              {this.state.orderBy == "id"
                ? this.state.orderAsc
                  ? " ▼"
                  : " ▲"
                : " -"}
            </td>
            <td
              className="admin-table-label-x"
              onClick={() => this.onSetOrderJobOffer("createdAt")}
            >
              생성일
              {this.state.orderBy == "startedAt"
                ? this.state.orderAsc
                  ? " ▼"
                  : " ▲"
                : " -"}
            </td>
            <td
              className="admin-table-label-x"
              onClick={() => this.onSetOrderJobOffer("status")}
            >
              상태
              {this.state.orderBy == "status"
                ? this.state.orderAsc
                  ? " ▼"
                  : " ▲"
                : " -"}
            </td>
            <td
              className="admin-table-label-x"
              onClick={() => this.onSetOrderJobOffer("workType")}
            >
              타입
              {this.state.orderBy == "workType"
                ? this.state.orderAsc
                  ? " ▼"
                  : " ▲"
                : " -"}
            </td>
            <td
              className="admin-table-label-x"
              onClick={() => this.onSetOrderJobOffer("companyRegion")}
            >
              지역
              {this.state.orderBy == "companyRegion"
                ? this.state.orderAsc
                  ? " ▼"
                  : " ▲"
                : " -"}
            </td>
            <td
              className="admin-table-label-x"
              onClick={() => this.onSetOrderJobOffer("companyName")}
            >
              회사명
              {this.state.orderBy == "companyName"
                ? this.state.orderAsc
                  ? " ▼"
                  : " ▲"
                : " -"}
            </td>
            <td className="admin-table-label-x">사용자ID</td>
            <td className="admin-table-label-x">담당자</td>
            <td className="admin-table-label-x">연락처</td>
            <td className="admin-table-label-x">Email</td>
            <td
              className="admin-table-label-x"
              onClick={() => this.onSetOrderJobOffer("initCandidateCnt")}
            >
              알림수
              {this.state.orderBy == "initCandidateCnt"
                ? this.state.orderAsc
                  ? " ▼"
                  : " ▲"
                : " -"}
            </td>
            <td className="admin-table-label-x">제목</td>
          </tr>
        </thead>
        <tbody id="admin-table-body">
          {this.state.items.map((offer: JobOfferInfo, index) => {
            if (
              this.state.search &&
              (!offer.id ||
                offer.id.toString().includes(this.state.search) == false) &&
              (!offer.companyName ||
                offer.companyName.includes(this.state.search) == false) &&
              (!offer.companyRegion ||
                offer.companyRegion.includes(this.state.search) == false) &&
              (!offer.ownerBizId ||
                offer.ownerBizId.includes(this.state.search) == false) &&
              (!offer.bizOwner ||
                !offer.bizOwner.userName ||
                offer.bizOwner.userName.includes(this.state.search) == false) &&
              (!offer.bizOwner ||
                !offer.bizOwner.phone ||
                offer.bizOwner.phone.includes(this.state.search) == false)
            )
              return null;

            let period = -1;
            if (offer.startedAt) {
              let end = 0;
              if (offer.endedAt) {
                end = new Date(offer.endedAt).valueOf();
              } else {
                end = Date.now() - 9 * 60 * 60 * 1000;
              }

              let start = new Date(offer.startedAt).valueOf();
              start = Math.floor(start / (24 * 60 * 60 * 1000));
              end = Math.floor(end / (24 * 60 * 60 * 1000));
              period = end - start;
            }

            let style = "";
            if (offer.status == JobOfferStatus.ENDED) {
              if (offer.passCnt > 0) style = " admin-table-value-highlight";
              else style = " admin-table-value-dimm";
            }

            let row = (
              <tr
                key={index.toString()}
                onClick={() => this.props.onSelectOffer(offer)}
              >
                <td className={"admin-table-value" + style}>{offer.id}</td>
                <td className={"admin-table-value" + style}>
                  {offer.createdAt
                    ? getDateStringFromToday({ date: offer.createdAt })
                    : ""}
                </td>
                <td className={"admin-table-value" + style}>
                  {this.getOfferTerm(offer)}
                </td>
                <td className={"admin-table-value" + style}>
                  {BizJobOfferName[offer.workType]}
                </td>
                <td className={"admin-table-value" + style}>
                  {offer.pharmacy.region}
                </td>
                <td className={"admin-table-value" + style}>
                  {offer.companyName}
                </td>
                <td className={"admin-table-value" + style}>
                  {offer.ownerBizId && offer.ownerBizId.slice(0, 8)}
                </td>
                <td className={"admin-table-value" + style}>
                  {offer.bizOwner && offer.bizOwner.userName}
                </td>
                <td className={"admin-table-value" + style}>
                  {offer.bizOwner && offer.bizOwner.phone && (
                    <Flex alignItems="center" gap="4px">
                      {offer.bizOwner.phone}
                      <Button
                        size={"Small"}
                        type={"Icon"}
                        variant={"Contained"}
                        color={"Primary"}
                        icon="Call"
                        onClick={this.onCall(offer.bizOwner.phone)}
                      />
                    </Flex>
                  )}
                </td>
                <td className={"admin-table-value" + style}>
                  {offer.bizOwner && offer.bizOwner.email && (
                    <Flex
                      alignItems="center"
                      gap="4px"
                      title={offer.bizOwner.email}
                    >
                      {offer.bizOwner.email.slice(0, 8)}
                      <Button
                        size={"Small"}
                        type={"Icon"}
                        variant={"Contained"}
                        color={"Primary"}
                        icon="Copy"
                        onClick={this.onEmail(offer.bizOwner.email, offer)}
                      />
                    </Flex>
                  )}
                </td>
                <td className={"admin-table-value" + style}>
                  {offer.initCandidateCnt}
                </td>
                <td className={"admin-table-value" + style}>{offer.title}</td>
              </tr>
            );

            return row;
          })}
        </tbody>
      </table>
    );
  };
}

const mapStateToProps = (state: RootState) => ({
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(JobpostCandidateList);
