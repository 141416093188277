import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../../../store";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonInput,
  IonText,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonIcon,
  IonButtons,
  IonMenuButton,
  IonList,
  IonSegmentButton,
  IonButton,
  IonSearchbar,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonFab,
  IonFabList,
  IonFabButton,
  IonAlert,
  IonBadge,
  IonLabel,
} from "@ionic/react";
import "./../../Admin.scss";
import { log, LogLevel } from "../../../utils/LogUtil";
import { setGlobal, GlobalKey, getGlobal } from "../../../utils/GlobalUtil";
import { fetchAPI } from "../../../utils/APIUtil";
import Truncate from "react-truncate";
import {
  SeminarLecture,
  SeminarLectureAttribute,
} from "../../../models/Model.Seminar";
import { UIPopupType } from "../../../store/ui/types";
import { STATUS_CODES } from "http";
import { idText, StringLiteralLike } from "typescript";
import { getDateStringFromToday } from "../../../utils/TimeUtil";
import { timingSafeEqual } from "crypto";
import ReactHtmlParser from "react-html-parser";
import { getOS } from "../../../utils/DeviceUtil";
import { isThisHour } from "date-fns";
import * as API from "../../../API.json";
import StringUtil from "../../../utils/StringUtil";
import Textarea from "react-textarea-autosize";
import DownloadUtil from "../../../utils/DownloadUtil";
import { MY_ADDRESS } from "../../../config.json";

const AdminAPI = {
  DAILY_PHARM_COMPANY_RESTORE: {
    method: "GET",
    path: "/poc/dailypharm/updatecompany",
    contentType: "application/json",
  },
  DAILY_PHARM_COMPANY_LIST: {
    method: "GET",
    path: "/poc/dailypharm/company",
    contentType: "application/json",
  },
  DAILY_PHARM_RECRUIT_LIST: {
    method: "GET",
    path: "/poc/dailypharm/recruits",
    contentType: "application/json",
  },
  DAILY_PHARM_COMPANY_UPDATE: {
    method: "PUT",
    path: "/poc/dailypharm/company",
    contentType: "application/json",
  },
};

type Props = RouteComponentProps<{ tab: string }> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    onSelectRecruit: (recruit: string) => void;
    onSelectOfferId: (id: number) => void;
    onSelectUserId: (id: number) => void;
  };

type State = {
  companyList: DailyPharmRecruitCompany[];
  type: string;
  order: string;
  subOrder: string;
  selectedIndex: number;
  finished: boolean;
  dateField: string;
  dateStart: string;
  dateStartWarn: boolean;
  dateEnd: string;
  dateEndWarn: boolean;
  editMessage: boolean;
  message: string;
  unitCount: number;
};

interface DailyPharmRecruitCompany {
  id?: number;
  groupid?: number;
  type?: string; // 모집부문 1
  name?: string; // 기업명
  region1?: string; // 지역(도시)
  region2?: string; // 지역(시구군)
  address?: string;
  position?: any; //{x,y}
  positionX?: number; //{x,y}
  positionY?: number; //{x,y}
  inCharge?: string;
  phone?: string;
  contact?: string[];
  contactJson?: string;
  lastRecruitedAt?: string;
  lastPharmacyRecruitedAt?: string;
  totalRecruitCnt?: number;
  pharmacistRecruitCnt?: number;
  candidateCnt?: number;
  candidateIn10km?: number;
  candidateIn20km?: number;
  recruits?: DailyPharmRecruit[];
  messagedAt?: string;

  userId?: number;
  joinedAt?: string;

  lastYmydRecruitedAt?: string;
  lastYmydRecruitId?: number;
}

interface DailyPharmRecruit {
  code?: string;
  companyId?: number;
  company?: DailyPharmRecruitCompany;
  category?: string;
  title?: string;
  workType?: string; // 모집부문 2
  hireType?: string; // 고용형태
  salary?: string; // 급여조건
  createdAt?: string;
  checkedAt?: string;
  skipped?: boolean;
  detail?: any;
}

class DailyPharmRecruitCompanyList extends Component<Props, State> {
  totalCount = 9500;
  // message_default = `안녕하세요 국장님,\n약사전용 서비스 약문약답입니다.\n\n팜리크루트 약사 구인 올리신 것 보고 도움 드리고자 연락드립니다 ^^\n\n약문약답 구인구직 서비스에 구인공고를 등록하시면, 약국 근처의 구직약사 #{candidateCnt}명에게 구인알림을 무료로 발송해 드립니다.  \n\n해당 서비스는 구인구직에 낭비되는 시간을 줄이고자 약사님들의 의견을 토대로 만든 서비스입니다. (회원수 #{totalCnt}명)\n\n약문약답에 구인공고를 등록하시면\n📌 약국장님 연락처 노출없이 구인 알림이 발송됩니다\n📌 지원서를 앱 안에서 모아볼 수 있어요\n📌 지원자 연락처 저장없이 편리하게 연락할 수 있어요\n📌 급하게 근무약사가 필요할 땐 긴급구인을 할 수 있어요\n\n★ 공고는 1번만 등록하면 됩니다\n★ 약문약답에는 한약사, 브로커 등 비약사는 없습니다\n☀️ 기존 구인구직 서비스와 차별점은 아래 링크에서 확인해보세요\nhttp://bit.ly/3bNs6Lj\n\n[약문약답 서비스소개 및 회원가입]\nhttps://service.ymyd.co.kr\n\n[약문약답 앱 다운로드]\n안드로이드: http://bit.ly/YmYd_android\n아이폰: http://bit.ly/YmYd_iphone`
  // message_default = `안녕하세요 국장님,\n공부하는 근무약사의 필수앱, 약문약답입니다.\n\n약문약답에 구인공고를 등록하시고 편리한 구인을 경험해 보세요 ^^\n구인공고 등록하시면 ☕️ 커피쿠폰 보내드려요~ \n\n[서비스 특징]\n📌 공부하는 약사들이 모여 있습니다 (☝️약문약답 회원수 #{totalCnt}명)\n📌 공고에 국장님 연락처가 노출되지 않아요.\n📌 약국 인근 구직약사 #{candidateCnt}명에게 구인공고가 카톡으로 발송됩니다. \n    (카톡내용은 약국명 없이 구인 조건으로만 구성되어요)\n\n[서비스 소개]\nhttps://service.ymyd.co.kr/\n📌 PC에서 접속하시면 더욱 편리합니다 ^^\n\n[APP 다운로드]\n안드로이드: http://bit.ly/YmYd_android\n아이폰: http://bit.ly/YmYd_iphone`;
  message_default = `안녕하세요 국장님,\n유료화 된 팜리크루트를 대신할\n무료 약사채용 서비스, 약문약답을 소개드립니다.\n\n[특징]\n* #{totalCnt}명의 약사가 쓰고 있어요\n* 무료로 공고를 등록할 수 있어요\n* 공고를 매일 등록할 필요가 없어요\n* 지원자 관리가 편리해요\n\n[구직약사 수]\n* #{region2} 인근 구직약사 수는 #{candidateCnt}명입니다.\n\n[이용 방법]\n아래 사이트에서 확인해 주세요.\nhttps://service.ymyd.co.kr/recruit\n\n[문의 방법]\n궁금한 점 있으시면 아래 링크로 문의해 주세요\nymyd.channel.io\n\n* 약문약답은 약사만 가입할 수 있는 \n  약사전용 서비스입니다 ^^`;
  message = "";

  constructor(props: Props) {
    super(props);
    let message = getGlobal(GlobalKey.ADMIN_DFR_MESSAGE, true);
    if (!message) {
      message = this.message_default;
      this.message = this.message_default;
    } else {
      this.message = message;
    }

    this.state = {
      companyList: [],
      type: "약국",
      order: "lastPharmacyRecruitedAt",
      subOrder: "",
      selectedIndex: -1,
      finished: false,
      dateField: "last_pharmacy_recruited_at",
      dateStart: getDateStringFromToday(),
      dateStartWarn: false,
      dateEnd: getDateStringFromToday({ days: 1 }),
      dateEndWarn: false,
      editMessage: false,
      message,
      unitCount: 200,
    };
  }

  componentDidMount() {
    this.fetchCompanyData();
    fetchAPI(API.USER_COUNT, "", null, null).then((result) => {
      if (result && !result.error) {
        this.totalCount = result.data;
      }
    });
  }

  fetchCompanyData = async () => {
    let result = await fetchAPI(
      AdminAPI.DAILY_PHARM_COMPANY_LIST,
      "",
      {
        type: this.state.type,
        count: this.state.unitCount,
        startDate: this.state.dateStart,
        endDate: this.state.dateEnd,
        dateField: this.state.dateField,
      },
      null,
      getGlobal(GlobalKey.TOKEN)
    );
    if (result && !result.error) {
      this.setState({
        companyList: result.data,
        finished: result.data.length < this.state.unitCount,
      });
    } else {
      this.setState({ companyList: [], finished: true });
    }
    log(
      LogLevel.UI_EVENT,
      "DailyPharmRecruitCompanyList:fetchCompanyData",
      result
    );
  };

  fetchCompanyRestore = async () => {
    let result = await fetchAPI(
      AdminAPI.DAILY_PHARM_COMPANY_RESTORE,
      "",
      null,
      null,
      getGlobal(GlobalKey.TOKEN)
    );
    log(
      LogLevel.UI_EVENT,
      "DailyPharmRecruitCompanyList:fetchCompanyData",
      result
    );
  };

  loading = false;
  fetchCompanyDataMore = async () => {
    if (this.loading) return;
    this.loading = true;
    let result = await fetchAPI(
      AdminAPI.DAILY_PHARM_COMPANY_LIST,
      "",
      {
        type: this.state.type,
        start: this.state.companyList.length,
        count: this.state.unitCount,
        startDate: this.state.dateStart,
        endDate: this.state.dateEnd,
        dateField: this.state.dateField,
      },
      null,
      getGlobal(GlobalKey.TOKEN)
    );
    if (result && !result.error) {
      this.setState({
        companyList: [...this.state.companyList, ...result.data],
        finished: result.data.length < this.state.unitCount,
      });
    } else {
      this.setState({ finished: true });
    }
    this.loading = false;
    log(
      LogLevel.UI_EVENT,
      "DailyPharmRecruitCompanyList:fetchCompanyData",
      result
    );
  };

  fetchRecruitData = async () => {
    if (
      this.state.selectedIndex >= 0 &&
      (!this.state.companyList[this.state.selectedIndex].recruits ||
        !this.state.companyList[this.state.selectedIndex].recruits.length)
    ) {
      let companyList = [...this.state.companyList];
      let company = { ...companyList[this.state.selectedIndex] };

      let result = await fetchAPI(
        AdminAPI.DAILY_PHARM_RECRUIT_LIST,
        "",
        { company: company.id },
        null,
        getGlobal(GlobalKey.TOKEN)
      );
      if (result && !result.error) {
        company.recruits = result.data ? result.data : [];
      }

      companyList[this.state.selectedIndex] = company;

      this.setState({ companyList });
      // log(LogLevel.UI_EVENT, "DailyPharmRecruitCompanyList:fetchCompanyData", result);
    }
  };

  onClickChangeMessage = () => {
    this.setState({ editMessage: true });
  };

  onCopy = () => {
    let windowAny: any = window;
    let $ = windowAny.$;
    window.getSelection().removeAllRanges();
    // window.open('data:application/vnd.ms-excel,' + $('#table').html());
    var body = document.getElementById("admin-table-body");
    var range = document.createRange();
    range.selectNode(body);
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    this.props.toastPopup.show("클립보드에 저장되었습니다.(안되었을수도 있음)");
  };

  onDownload = () => {
    let csv =
      "ID,타입,회사명,지역1,지역2,담당자,연락처,최근광고일,가입일(ID),구인일(ID),최근구인일,최근약사구인일,구인횟수,약사구인횟수,후보자,후보자10km,후보자20km\r\n";
    csv += this.state.companyList
      .map((item: DailyPharmRecruitCompany, index) => {
        let rvalue = "";
        rvalue += `${item.id},`;
        rvalue += `${item.type},`;
        rvalue += `${item.name},`;
        rvalue += `${item.region1},`;
        rvalue += `${item.region2},`;
        rvalue += `${item.inCharge},`;
        rvalue += `${item.phone},`;
        rvalue += `${
          item.messagedAt
            ? getDateStringFromToday({ date: item.messagedAt })
            : ""
        },`;
        rvalue += `${
          item.userId
            ? getDateStringFromToday({ date: item.joinedAt }) +
              "(" +
              item.userId +
              ")"
            : ""
        },`;
        rvalue += `${
          item.lastYmydRecruitId
            ? getDateStringFromToday({ date: item.lastYmydRecruitedAt }) +
              "(" +
              item.lastYmydRecruitId +
              ")"
            : ""
        },`;
        rvalue += `${
          item.lastRecruitedAt ? item.lastRecruitedAt.substring(0, 10) : ""
        },`;
        rvalue += `${
          item.lastPharmacyRecruitedAt
            ? item.lastPharmacyRecruitedAt.substring(0, 10)
            : ""
        },`;
        rvalue += `${item.totalRecruitCnt},`;
        rvalue += `${item.pharmacistRecruitCnt},`;
        rvalue += `${item.candidateCnt},`;
        rvalue += `${item.candidateIn10km},`;
        rvalue += `${item.candidateIn20km}\r\n`;

        return rvalue;
      })
      .join("");

    DownloadUtil.downloadCsv(
      csv,
      `DailyPharm-Companies-${this.state.dateStart}-${
        this.state.dateEnd
      }-${new Date().toISOString().substring(0, 10).replace(/-/gi, "")}.csv`
    );
  };

  onSaveMessage = () => {
    let message = this.state.message;
    if (!message) message = this.message_default;
    this.message = message;
    setGlobal(GlobalKey.ADMIN_DFR_MESSAGE, message, true);
    this.setState({ message, editMessage: false });
  };

  onDetail = async (index) => {
    if (this.state.selectedIndex == index) index = -1;
    this.setState({ selectedIndex: index });

    setTimeout(() => {
      this.fetchRecruitData();
    }, 100);
  };

  onSelectRecruit = (detail) => {
    this.props.onSelectRecruit(detail);
  };

  onOrder = (order) => {
    let subOrder = "desc";
    if (order == this.state.order && this.state.subOrder == "desc")
      subOrder = "asc";

    console.log("onOrder", order, subOrder);
    let companyList = this.state.companyList.sort((a, b) => {
      if (order == "region") {
        return (
          (a["region1"] < b["region1"]
            ? -1
            : a["region1"] > b["region1"]
            ? 1
            : a["region2"] < b["region2"]
            ? -1
            : a["region2"] > b["region2"]
            ? 1
            : 0) * (subOrder == "desc" ? -1 : 1)
        );
      } else {
        return (
          (a[order] < b[order] || a[order] == null
            ? -1
            : a[order] > b[order] || b[order] == null
            ? 1
            : 0) * (subOrder == "desc" ? -1 : 1)
        );
      }
    });
    this.setState({ order, subOrder, companyList });
  };

  onDateChange = (e, field) => {
    let dateString = e.target.value;
    let dateWarn = false;
    try {
      let date = Date.parse(dateString);
      if (!date) {
        log(
          LogLevel.UI_EXCEPTION,
          "Admin:DailyPharmRecruitCompanyList:onDateEndChange",
          dateString
        );
        dateWarn = true;
      } else {
        log(
          LogLevel.UI_DATA_LOAD,
          "Admin:DailyPharmRecruitCompanyList:onDateEndChange",
          dateString,
          date
        );
      }
    } catch (err) {
      log(
        LogLevel.UI_EXCEPTION,
        "Admin:DailyPharmRecruitCompanyList:onDateEndChange",
        dateString,
        err
      );
      dateWarn = true;
    }
    let newState: any = {};
    (newState[field] = dateString),
      (newState[field + "Warn"] = dateWarn),
      this.setState(newState);
  };

  onJump = (code) => {
    window.open(
      "http://recruit.dailypharm.com/Offer/OfferView.php?ID=" + code,
      "_blank"
    );
  };

  render() {
    log(
      LogLevel.UI_LIFECYCLE,
      "DailyPharmRecruitCompanyList.render",
      this.props,
      this.state
    );

    let more;
    if (!this.state.finished) {
      more = (
        <IonButton expand="full" onClick={this.fetchCompanyDataMore}>
          더 불러오기
        </IonButton>
      );
    }

    let editMessage;
    if (this.state.editMessage) {
      editMessage = (
        <div className="common-container">
          <div>
            {
              "#{candidateCnt}, #{totalCnt}, #{candidateIn10KmCnt}, #{candidateIn20KmCnt}"
            }
          </div>
          <Textarea
            className="common-container"
            minRows={10}
            value={this.state.message}
            placeholder={this.message_default}
            onChange={(e) => {
              this.setState({ message: e.target.value });
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
          <IonButton onClick={this.onSaveMessage}>저장</IonButton>
        </div>
      );
    }
    return (
      <div className="admin-full-container">
        <div className="common-container-row-wrap admin-margin-bottom">
          <div className="admin-title">종류</div>
          <div
            className={
              "admin-toggle" +
              (this.state.type == "약국" ? " admin-toggle-selected" : "")
            }
            onClick={() => this.setState({ type: "약국" })}
          >
            약국
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.type == "병원" ? " admin-toggle-selected" : "")
            }
            onClick={() => this.setState({ type: "병원" })}
          >
            병원
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.type == "제약" ? " admin-toggle-selected" : "")
            }
            onClick={() => this.setState({ type: "제약" })}
          >
            제약
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.type == "한약사개설약국"
                ? " admin-toggle-selected"
                : "")
            }
            onClick={() => this.setState({ type: "한약사개설약국" })}
          >
            한약사개설약국
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.type == "" ? " admin-toggle-selected" : "")
            }
            onClick={() => this.setState({ type: "" })}
          >
            전체
          </div>
        </div>
        <div className="common-container-row-wrap admin-margin-bottom">
          <div className="admin-title">게시기간</div>
          <div
            className={
              "admin-toggle" +
              (this.state.dateField == "last_pharmacy_recruited_at"
                ? " admin-toggle-selected"
                : "")
            }
            onClick={() =>
              this.setState({ dateField: "last_pharmacy_recruited_at" })
            }
          >
            약사
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.dateField == "last_recruited_at"
                ? " admin-toggle-selected"
                : "")
            }
            onClick={() => this.setState({ dateField: "last_recruited_at" })}
          >
            전체
          </div>
          <input
            className={this.state.dateStartWarn ? "common-color-caution" : ""}
            placeholder="시작일시"
            value={this.state.dateStart}
            onChange={(e) => this.onDateChange(e, "dateStart")}
          />
          <div>~</div>
          <input
            className={this.state.dateEndWarn ? "common-color-caution" : ""}
            placeholder="시작일시"
            value={this.state.dateEnd}
            onChange={(e) => this.onDateChange(e, "dateEnd")}
          />

          <div
            className="admin-title common-color-highlight"
            onClick={() => {
              this.setState({
                dateStart: getDateStringFromToday(),
                dateEnd: getDateStringFromToday({ days: 1 }),
                dateStartWarn: false,
                dateEndWarn: false,
              });
            }}
          >
            오늘
          </div>
          <div
            className="admin-title common-color-highlight"
            onClick={() => {
              this.setState({
                dateStart: getDateStringFromToday({ days: -1 }),
                dateEnd: getDateStringFromToday(),
                dateStartWarn: false,
                dateEndWarn: false,
              });
            }}
          >
            어제
          </div>
          <div
            className="admin-title common-color-highlight"
            onClick={() => {
              this.setState({
                dateStart: getDateStringFromToday({ weeks: -1 }),
                dateEnd: getDateStringFromToday({ days: 1 }),
                dateStartWarn: false,
                dateEndWarn: false,
              });
            }}
          >
            1주
          </div>
        </div>
        <div className="common-container-row-wrap admin-margin-bottom">
          <div
            className={
              "admin-toggle" +
              (this.state.unitCount == 200 ? " admin-toggle-selected" : "")
            }
            onClick={() => this.setState({ unitCount: 200 })}
          >
            200row
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.unitCount == 1000 ? " admin-toggle-selected" : "")
            }
            onClick={() => this.setState({ unitCount: 1000 })}
          >
            1000row
          </div>
        </div>
        <IonButton onClick={this.fetchCompanyData}>불러오기</IonButton>
        {/* <IonButton onClick={this.fetchCompanyRestore}>데이터복원</IonButton> */}
        <IonButton onClick={this.onClickChangeMessage}>메시지수정</IonButton>
        <IonButton onClick={this.onCopy}>복사</IonButton>
        <IonButton onClick={this.onDownload}>
          다운로드({this.state.companyList.length}건)
        </IonButton>

        {editMessage}
        <div className="common-container">
          {more}
          <table id="admin-table" className="admin-table">
            <thead>
              <tr>
                <td className="admin-table-label-x">ID</td>
                <td className="admin-table-label-x">타입</td>
                <td className="admin-table-label-x">회사명</td>
                <td
                  className="admin-table-label-x"
                  onClick={() => this.onOrder("region")}
                >
                  지역1{" "}
                  {this.state.order == "region"
                    ? this.state.subOrder == "desc"
                      ? "▼"
                      : "▲"
                    : ""}
                </td>
                <td
                  className="admin-table-label-x"
                  onClick={() => this.onOrder("region")}
                >
                  지역2{" "}
                  {this.state.order == "region"
                    ? this.state.subOrder == "desc"
                      ? "▼"
                      : "▲"
                    : ""}
                </td>
                <td className="admin-table-label-x">담당자</td>
                <td className="admin-table-label-x">연락처</td>
                <td
                  className="admin-table-label-x"
                  onClick={() => this.onOrder("messagedAt")}
                >
                  최근광고일{" "}
                  {this.state.order == "messagedAt"
                    ? this.state.subOrder == "asc"
                      ? "▼"
                      : "▲"
                    : ""}
                </td>
                <td
                  className="admin-table-label-x"
                  onClick={() => this.onOrder("joinedAt")}
                >
                  Y가입일{" "}
                  {this.state.order == "joinedAt"
                    ? this.state.subOrder == "desc"
                      ? "▼"
                      : "▲"
                    : ""}
                </td>
                <td className="admin-table-label-x">Y사용자ID</td>
                <td
                  className="admin-table-label-x"
                  onClick={() => this.onOrder("lastYmydRecruitedAt")}
                >
                  Y구인일{" "}
                  {this.state.order == "lastYmydRecruitedAt"
                    ? this.state.subOrder == "desc"
                      ? "▼"
                      : "▲"
                    : ""}
                </td>
                <td className="admin-table-label-x">Y구인ID</td>
                <td
                  className="admin-table-label-x"
                  onClick={() => this.onOrder("lastRecruitedAt")}
                >
                  최근구인일{" "}
                  {this.state.order == "lastRecruitedAt"
                    ? this.state.subOrder == "desc"
                      ? "▼"
                      : "▲"
                    : ""}
                </td>
                <td
                  className="admin-table-label-x"
                  onClick={() => this.onOrder("lastPharmacyRecruitedAt")}
                >
                  최근약사구인일{" "}
                  {this.state.order == "lastPharmacyRecruitedAt"
                    ? this.state.subOrder == "desc"
                      ? "▼"
                      : "▲"
                    : ""}
                </td>
                <td className="admin-table-label-x">구인횟수</td>
                <td
                  className="admin-table-label-x"
                  onClick={() => this.onOrder("pharmacyRecruitCnt")}
                >
                  약사구인횟수{" "}
                  {this.state.order == "pharmacyRecruitCnt"
                    ? this.state.subOrder == "desc"
                      ? "▼"
                      : "▲"
                    : ""}
                </td>
                <td
                  className="admin-table-label-x"
                  onClick={() => this.onOrder("candidateCnt")}
                >
                  후보자{" "}
                  {this.state.order == "candidateCnt"
                    ? this.state.subOrder == "desc"
                      ? "▼"
                      : "▲"
                    : ""}
                </td>
                <td
                  className="admin-table-label-x"
                  onClick={() => this.onOrder("candidateIn10km")}
                >
                  후보자10km{" "}
                  {this.state.order == "candidateIn10km"
                    ? this.state.subOrder == "desc"
                      ? "▼"
                      : "▲"
                    : ""}
                </td>
                <td
                  className="admin-table-label-x"
                  onClick={() => this.onOrder("candidateIn20km")}
                >
                  후보자20km{" "}
                  {this.state.order == "candidateIn20km"
                    ? this.state.subOrder == "desc"
                      ? "▼"
                      : "▲"
                    : ""}
                </td>
                <td className="admin-table-label-x">더보기</td>
              </tr>
            </thead>
            <tbody id="admin-table-body">
              {this.state.companyList.map(
                (item: DailyPharmRecruitCompany, index) => {
                  let contact = <td className="admin-table-value" />;
                  if (this.state.selectedIndex == index) {
                    contact = (
                      <td className="admin-table-value">
                        {ReactHtmlParser(item.contact.join("<br/>"))}
                      </td>
                    );
                  } else {
                    if (item.phone) {
                      let os = getOS();
                      let message = StringUtil.createStringFromTemplate(
                        this.message,
                        {
                          candidateCnt: StringUtil.commafy(item.candidateCnt),
                          totalCnt: StringUtil.commafy(this.totalCount),
                          candidateIn10KmCnt: StringUtil.commafy(
                            item.candidateIn10km
                          ),
                          candidateIn20KmCnt: StringUtil.commafy(
                            item.candidateIn20km
                          ),
                          name: item.name, // 기업명
                          region1: item.region1, // 지역(도시)
                          region2: item.region2, // 지역(시구군)
                        }
                      );
                      contact = (
                        <td
                          className={
                            "admin-table-value" +
                            (item.messagedAt || item.candidateCnt < 5
                              ? " common-color-caution"
                              : "")
                          }
                          onClick={() => {
                            item.messagedAt = getDateStringFromToday();
                            fetchAPI(
                              AdminAPI.DAILY_PHARM_COMPANY_UPDATE,
                              "",
                              null,
                              { id: item.id, messagedAt: 1 },
                              getGlobal(GlobalKey.TOKEN)
                            );
                            this.setState({
                              companyList: [...this.state.companyList],
                            });
                            if (os == "android") {
                              window.open(
                                `sms:${item.phone}?body=${encodeURIComponent(
                                  message
                                )}`,
                                "_system"
                              );
                              // window.open(`com.onjourney.pharmacycafe://open?type=jobpost`,"_system");
                              // window.open(`sms:${item.phone},01031507929?body=${encodeURIComponent(`${name} 안녕하세요. ${pname}`)}`,"_system");
                            } else if (os == "ios" || os == "macos") {
                              window.open(
                                `sms:${item.phone}&body=${encodeURIComponent(
                                  message
                                )}`,
                                "_system"
                              );
                              // window.open(`com.onjourney.pharmacycafe://open?type=jobpost`,"_system");
                            }
                          }}
                        >
                          {item.phone}
                        </td>
                      );
                    }
                  }
                  let row = (
                    <tr key="0">
                      <td className="admin-table-value">{item.id}</td>
                      <td className="admin-table-value">{item.type}</td>
                      <td className="admin-table-value">{item.name}</td>
                      <td className="admin-table-value">{item.region1}</td>
                      <td className="admin-table-value">{item.region2}</td>
                      <td className="admin-table-value">{item.inCharge}</td>
                      {contact}
                      <td
                        className={
                          "admin-table-value" +
                          (item.messagedAt ? " common-color-caution" : "")
                        }
                      >
                        {item.messagedAt
                          ? getDateStringFromToday({ date: item.messagedAt })
                          : ""}
                      </td>
                      <td
                        className={
                          "admin-table-value" +
                          (item.messagedAt ? " common-color-caution" : "")
                        }
                      >
                        {item.userId
                          ? getDateStringFromToday({ date: item.joinedAt })
                          : ""}
                      </td>
                      <td
                        className={
                          "admin-table-value" +
                          (item.messagedAt ? " common-color-caution" : "")
                        }
                        onClick={() => {
                          if (item.userId)
                            this.props.onSelectUserId(item.userId);
                        }}
                      >
                        {item.userId ? item.userId : ""}
                      </td>
                      <td
                        className={
                          "admin-table-value" +
                          (item.messagedAt ? " common-color-caution" : "")
                        }
                      >
                        {item.lastYmydRecruitId
                          ? getDateStringFromToday({
                              date: item.lastYmydRecruitedAt,
                            })
                          : ""}
                      </td>
                      <td
                        className={
                          "admin-table-value" +
                          (item.messagedAt ? " common-color-caution" : "")
                        }
                        onClick={() => {
                          if (item.lastYmydRecruitId)
                            this.props.onSelectOfferId(item.lastYmydRecruitId);
                        }}
                      >
                        {item.lastYmydRecruitId ? item.lastYmydRecruitId : ""}
                      </td>
                      <td className="admin-table-value">
                        {item.lastRecruitedAt
                          ? item.lastRecruitedAt.substring(0, 10)
                          : ""}
                      </td>
                      <td className="admin-table-value">
                        {item.lastPharmacyRecruitedAt
                          ? item.lastPharmacyRecruitedAt.substring(0, 10)
                          : ""}
                      </td>
                      <td className="admin-table-value">
                        {item.totalRecruitCnt}
                      </td>
                      <td className="admin-table-value">
                        {item.pharmacistRecruitCnt}
                      </td>
                      <td
                        className={
                          "admin-table-value" +
                          (item.candidateCnt < 5
                            ? " common-color-caution"
                            : " common-color-highlight")
                        }
                      >
                        {item.candidateCnt}
                      </td>
                      <td className="admin-table-value">
                        {item.candidateIn10km}
                      </td>
                      <td className="admin-table-value">
                        {item.candidateIn20km}
                      </td>
                      <td
                        className="admin-table-value"
                        onClick={() => this.onDetail(index)}
                      >
                        {index == this.state.selectedIndex ? "<" : ">"}
                      </td>
                    </tr>
                  );
                  let array = [row];
                  if (
                    index == this.state.selectedIndex &&
                    item.recruits &&
                    item.recruits.length
                  ) {
                    let additionalRow = (
                      <tr key="1">
                        <td />
                        <td colSpan={12}>
                          <table className="admin-table">
                            <thead>
                              <tr>
                                <td className="admin-table-label-y">CODE</td>
                                <td className="admin-table-label-y">범주</td>
                                <td className="admin-table-label-y">제목</td>
                                <td className="admin-table-label-y">
                                  근무유형
                                </td>
                                <td className="admin-table-label-y">
                                  고용유형
                                </td>
                                <td className="admin-table-label-y">봉급</td>
                                <td className="admin-table-label-y">최초</td>
                                <td className="admin-table-label-y">확인</td>
                                <td className="admin-table-label-y">
                                  넘어가기
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              {item.recruits.map(
                                (item: DailyPharmRecruit, index) => {
                                  let row = (
                                    <tr key={index.toString()}>
                                      <td
                                        className={
                                          "admin-table-value" +
                                          (item.skipped
                                            ? " common-color-caution"
                                            : "")
                                        }
                                        onClick={() => {
                                          this.onJump(item.code);
                                        }}
                                      >
                                        {item.code}
                                      </td>
                                      <td
                                        className={
                                          "admin-table-value" +
                                          (item.skipped
                                            ? " common-color-caution"
                                            : "")
                                        }
                                      >
                                        {item.category}
                                      </td>
                                      <td
                                        className={
                                          "admin-table-value" +
                                          (item.skipped
                                            ? " common-color-caution"
                                            : "")
                                        }
                                      >
                                        {item.title}
                                      </td>
                                      <td
                                        className={
                                          "admin-table-value" +
                                          (item.skipped
                                            ? " common-color-caution"
                                            : "")
                                        }
                                      >
                                        {item.workType}
                                      </td>
                                      <td
                                        className={
                                          "admin-table-value" +
                                          (item.skipped
                                            ? " common-color-caution"
                                            : "")
                                        }
                                      >
                                        {item.hireType}
                                      </td>
                                      <td
                                        className={
                                          "admin-table-value" +
                                          (item.skipped
                                            ? " common-color-caution"
                                            : "")
                                        }
                                      >
                                        {item.salary}
                                      </td>
                                      <td
                                        className={
                                          "admin-table-value" +
                                          (item.skipped
                                            ? " common-color-caution"
                                            : "")
                                        }
                                      >
                                        {item.createdAt
                                          ? getDateStringFromToday({
                                              date: item.createdAt,
                                            })
                                          : ""}
                                      </td>
                                      <td
                                        className={
                                          "admin-table-value" +
                                          (item.skipped
                                            ? " common-color-caution"
                                            : "")
                                        }
                                      >
                                        {item.checkedAt
                                          ? getDateStringFromToday({
                                              date: item.checkedAt,
                                            })
                                          : ""}
                                      </td>
                                      <td
                                        className="admin-table-value"
                                        onClick={() => {
                                          this.onSelectRecruit(item);
                                        }}
                                      >
                                        {">"}
                                      </td>
                                    </tr>
                                  );
                                  return row;
                                }
                              )}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    );
                    array.push(additionalRow);
                  }

                  return array;
                }
              )}
            </tbody>
          </table>
          {more}
          <div style={{ height: "100px" }} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  lectures: state.seminar.lectures,
  progressPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  filePath: state.board.filePath,
});

const mapDispatchToProps = {
  //   logOutUser: () => actions.user.logout(),
  //   refreshBoards: () => actions.board.refreshBoards(),
  loadLecture: (lectureId: number) =>
    actions.seminar.getSeminarLecture(lectureId),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DailyPharmRecruitCompanyList)
);
