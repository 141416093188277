import React, { Component } from "react";
import "./../../../Common.css";
import "./../../Admin.scss";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonButton,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonList,
  IonItem,
  IonTextarea,
  IonInput,
  IonCheckbox,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonLabel,
} from "@ionic/react";
import { fetchAPI } from "./../../../utils/APIUtil";
import { timeout, async } from "q";
import smileIcon from "./../assets/icon/smile.svg";
import { log, LogLevel } from "../../../utils/LogUtil";
import {
  AllInOnePartnerInfo,
  UserWorkTypeName,
  UserLevel,
  UserLevelName,
  RegisterRoute,
  REGISTER_ROUTE_NAME,
  UserPermanentType,
  UserWorkType,
  AllInOnePartnerLevel,
  CompanyType,
  CompanyTypeName,
  PARTNERS_TEMPLATE,
  UserTypeName,
  UserType,
} from "../../../models/Model.User";
import { getGlobal, GlobalKey, setGlobal } from "../../../utils/GlobalUtil";
import { triggerAsyncId } from "async_hooks";
import { actions, RootState } from "../../../store";
import { connect } from "react-redux";
import { UIPopupType } from "../../../store/ui/types";
import { BoardAttribute } from "../../../models/Model.Board";
import {
  getDateStringFromToday,
  getDateTimeString,
} from "../../../utils/TimeUtil";
import StringUtil from "../../../utils/StringUtil";
import IconButton from "../../../component/atom/IconButton";
import { callPhone, sendSMSMessage } from "../../../utils/UrlSchemeUtil";
import { JobOfferName } from "../../../models/Model.JobPost";
import Button from "../../../components/atoms/Button";
import * as API from "../../../API.json";
import parse from "html-react-parser";
import ReactDOMServer from "react-dom/server";
import {
  loadImageBase64,
  loadImageUrlBase64,
} from "./../../../utils/ImageUtil";
import ImageViewNSelect, { ImageSize } from "../ImageViewNSelect";
import HtmlComposer from "../../../components/HtmlComposer";
import Textarea from "react-textarea-autosize";

const AdminAPI = {
  PARTNER_ADD: {
    method: "POST",
    path: "/admin/allinone/partner",
    contentType: "application/json",
  },
  PARTNER_UPDATE: {
    method: "PUT",
    path: "/admin/allinone/partner",
    contentType: "application/json",
  },
  PARTNER_PATCH: {
    method: "PATCH",
    path: "/admin/allinone/partner",
    contentType: "application/json",
  },
  PARTNER_LOGIN: {
    method: "GET",
    path: "/admin/allinone/auth",
    contentType: "application/json",
  },
  PARTNER_MIGRATION: {
    method: "GET",
    path: "/admin/allinone/migration/partner",
    contentType: "application/json",
  },
  PARTNER_DELETE: {
    method: "DELETE",
    path: "/admin/allinone/partner/",
    contentType: "application/json",
  },
};

type Props = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    onDone: () => void;
    onCancel: () => void;
    partner: AllInOnePartnerInfo;
  };

type State = AllInOnePartnerInfo & {
  edited?: boolean;
};

class AllInOnePartnerManageProfile extends Component<Props, State> {
  state: State = { ...PARTNERS_TEMPLATE, edited: false };
  constructor(props: Props) {
    super(props);
    if (props.partner) {
      log(
        LogLevel.UI_DATA_LOAD,
        "AllInOnePartnerManageProfile",
        this.props.partner
      );
      this.state = { ...props.partner, edited: false };
    }
  }

  componentDidUpdate(prevProps, nextState) {
    if (
      this.props.partner &&
      (!prevProps ||
        !prevProps.partner ||
        this.props.partner.id != prevProps.partner.id)
    ) {
      this.setState({
        logoImageBase64: "",
        ...this.props.partner,
        edited: false,
      });
    }
  }

  onBack = () => {
    if (this.state.edited) {
      this.props.confirmPopup.show({
        title: (
          <div className="common-container">
            <span className="common-bold">수정을 그만두시겠습니까?</span>
          </div>
        ),
        body: <div className="common-container">수정사항이 있습니다.</div>,
        onDone: this.props.onCancel,
        doneText: "네",
      });
    } else {
      this.props.onCancel;
    }
  };

  onlogoSelected = async (e, max = 1920, profile = false) => {
    // console.dir(e);
    log(
      LogLevel.UI_ACTION,
      "AllInOnePartnerManageProfile:onlogoSelected",
      e.target.files
    );
    this.props.progressPopup.show();
    this.props.progressPopup.setLabel("이미지 로딩 중...");

    if (e.target.files && e.target.files.length > 0) {
      let base64 = await loadImageBase64(e.target.files[0], max, profile);
      if (base64) {
        this.setState({ edited: true, logoImageBase64: base64 });
      }
    }
    this.props.progressPopup.hide();
  };

  onDelete = async () => {
    if (this.props.partner.id) {
      this.props.confirmPopup.show({
        title: (
          <div className="common-container">
            <span className="common-bold common-color-caution">
              경고!!! 법인계정을 삭제하시겠습니까?
            </span>
          </div>
        ),
        body: <div className="common-container">복구되지 않습니다.</div>,
        onDone: this.doDelete,
        doneText: "네",
      });
    }
  };

  doDelete = async () => {
    this.props.progressPopup.setLabel("약사 파트너 계정삭제 시작...");
    let api = AdminAPI.PARTNER_DELETE;
    let result = await fetchAPI(
      api,
      this.props.partner.id,
      null,
      null,
      getGlobal(GlobalKey.TOKEN)
    );
    if (result && !result.error) {
      this.props.toastPopup.show("계정삭제가 완료되었습니다.");
      this.props.onDone();
    } else {
      log(
        LogLevel.UI_EXCEPTION,
        "AllInOnePartnerManageProfile Update failed",
        result
      );
      this.props.progressPopup.hide();
      this.props.toastPopup.show("사용자 정보 저장이 실패했습니다.");
    }

    this.props.progressPopup.hide();
  };

  onMigration = async () => {
    this.props.progressPopup.show();
    this.props.progressPopup.setLabel("약사 파트너 마이그레이션 시작...");
    let api = AdminAPI.PARTNER_MIGRATION;
    let result = await fetchAPI(
      api,
      "",
      { bizId: this.props.partner.id },
      null,
      getGlobal(GlobalKey.TOKEN)
    );
    if (result && !result.error) {
      this.props.toastPopup.show("마이그레이션이 완료되었습니다.");
      this.props.onDone();
    } else {
      log(
        LogLevel.UI_EXCEPTION,
        "AllInOnePartnerManageProfile Update failed",
        result
      );
      this.props.progressPopup.hide();
      this.props.toastPopup.show("사용자 정보 저장이 실패했습니다.");
    }

    this.props.progressPopup.hide();
  };

  onDone = async (granted: boolean = false) => {
    if (!this.state.edited) return;

    this.props.progressPopup.show();
    this.props.progressPopup.setLabel("약사 파트너 저장 시작...");

    let updatePartner: AllInOnePartnerInfo = {
      ...this.props.partner,
      ...this.state,
    };

    updatePartner.companyRegion = updatePartner.companyAddress
      .split(" ")
      .slice(0, 2)
      .join(" ");
    let html = updatePartner.introductionHTML;

    if (html) {
      let innerImages = [];
      let a: any = parse(html, {
        replace: (domNode) => {
          // console.log(domNode);
          if (
            domNode.type == "tag" &&
            domNode.name == "img" &&
            domNode.attribs.src
          ) {
            if (domNode.attribs.src.startsWith("data:image")) {
              let index = innerImages.length;
              innerImages.push(domNode.attribs.src);
              domNode.attribs.src = `-#-INNER_IMAGE-${index}-#-`;
            }
          }
          return domNode;
        },
      });
      html = ReactDOMServer.renderToStaticMarkup(a);
      html = html.substring(5, html.length - 6);

      this.props.progressPopup.setLabel("이미지 업로드 중...");
      for (let i = 0; i < innerImages.length; i++) {
        let data: any = { path: "allinone" };
        if (innerImages[i].startsWith("data:image")) {
          data.base64 = innerImages[i];
        } else if (innerImages[i].startsWith("http://")) {
          data.base64 = await loadImageUrlBase64(innerImages[i]);
        }
        data.fullPath = true;

        try {
          let result = await fetchAPI(
            API.UPLOAD_BASE64,
            "",
            null,
            data,
            getGlobal(GlobalKey.TOKEN)
          );
          if (result && !result.error) {
            let url = result.file;
            html = html.replace(`-#-INNER_IMAGE-${i}-#-`, url);
          }
        } catch (error) {
          log(
            LogLevel.UI_ACTION,
            "AllInOnePartnerManageProfile.onDone : upload error",
            error
          );
          this.props.progressPopup.hide();
          this.props.toastPopup.show("html 설명 업로드가. 실패했습니다.");
          return;
        }
      }
    }
    updatePartner.introductionHTML = html;

    let logoImageUrlBase64 = updatePartner.logoImageBase64;
    updatePartner.logoImageBase64 = "";
    if (logoImageUrlBase64) {
      let data: any = { path: "allinone" };
      data.base64 = logoImageUrlBase64;
      try {
        let result = await fetchAPI(
          API.UPLOAD_BASE64,
          "",
          null,
          data,
          getGlobal(GlobalKey.TOKEN)
        );
        if (result && !result.error) {
          updatePartner.logoImageUrl = result.file;
        }
      } catch (error) {
        log(
          LogLevel.UI_ACTION,
          "AllInOnePartnerManageProfile.onDone : upload error",
          error
        );
        this.props.progressPopup.hide();
        this.props.toastPopup.show("로고 이미지 업로드가 실패했습니다.");
        return;
      }
    }

    if (granted) {
      updatePartner.registerGrantedAt = getDateStringFromToday();
    }
    log(
      LogLevel.UI_ACTION,
      "AllInOnePartnerManageProfile Update",
      updatePartner
    );
    let api = AdminAPI.PARTNER_PATCH;
    if (!this.props.partner || !this.props.partner.id)
      api = AdminAPI.PARTNER_ADD;
    else if (this.props.partner.usersId) api = AdminAPI.PARTNER_UPDATE;
    else updatePartner.oldId = this.props.partner.id;
    let result = await fetchAPI(
      api,
      "",
      null,
      updatePartner,
      getGlobal(GlobalKey.TOKEN)
    );
    if (result && !result.error) {
      this.props.onDone();
    } else {
      log(
        LogLevel.UI_EXCEPTION,
        "AllInOnePartnerManageProfile Update failed",
        result
      );
      this.props.progressPopup.hide();
      this.props.toastPopup.show("사용자 정보 저장이 실패했습니다.");
    }

    this.setState(PARTNERS_TEMPLATE);

    this.props.progressPopup.hide();
  };

  onLogin = () => {
    if (!this.props.partner.id) return;
    this.props.confirmPopup.show({
      title: (
        <div className="common-container">
          <span className="common-bold">
            <span className="common-highlight">
              {this.props.partner.companyName}
            </span>{" "}
            계정으로 로그인 하시겠습니까??
          </span>
        </div>
      ),
      body: (
        <div className="common-container">
          다시 돌아오려면 반드시 재로그인을 해야합니다.
        </div>
      ),
      onDone: this.doLogin,
      doneText: "네",
    });
  };

  doLogin = async () => {
    if (!this.props.partner.id) return;
    let result = await fetchAPI(
      AdminAPI.PARTNER_LOGIN,
      "",
      { id: this.props.partner.id },
      null,
      getGlobal(GlobalKey.TOKEN)
    );
    if (result && !result.error && result.data && result.data.token) {
      setGlobal(GlobalKey.TOKEN, result.data.token, true);
      window.open(location.origin, "_blank");
    }
  };

  render() {
    log(
      LogLevel.UI_ACTION,
      "AllInOnePartnerManageProfile render",
      this.props.partner
    );
    if (this.props.partner) {
      return (
        <div className="admin-container">
          {this.props.onCancel && (
            <IonHeader class="common-header" no-border>
              <IonToolbar color="common-header-toolbar">
                <IonButtons slot="start" class="common-container">
                  <IonButton
                    class="common-header-toolbar-button"
                    onClick={this.onBack}
                  >
                    <IonIcon name="arrow-back" mode="ios" />
                  </IonButton>
                  <IonLabel class="common-header-title common-flex-grow">
                    법인회원 정보
                  </IonLabel>
                  {this.props.partner.id && (
                    <Button
                      disabled={!this.state.edited}
                      onClick={() => this.onDelete()}
                      size={"Small"}
                      type={"Text"}
                      variant={"Contained"}
                      color={"Negative"}
                      style={{ marginRight: "50px" }}
                    >
                      삭제
                    </Button>
                  )}
                  {this.props.partner.id.length < 20 && (
                    <Button
                      disabled={!this.state.edited}
                      onClick={() => this.onMigration()}
                      size={"Small"}
                      type={"Text"}
                      variant={"Contained"}
                      color={"Negative"}
                      style={{ marginRight: "10px" }}
                    >
                      Migration
                    </Button>
                  )}
                  {!this.props.partner.registerGrantedAt && (
                    <Button
                      disabled={!this.state.edited}
                      onClick={() => this.onDone(true)}
                      size={"Small"}
                      type={"Text"}
                      variant={"Contained"}
                      color={"Primary"}
                      style={{ marginRight: "10px" }}
                    >
                      가입승인
                    </Button>
                  )}
                  <Button
                    disabled={!this.state.edited}
                    onClick={() => this.onDone()}
                    size={"Small"}
                    type={"Text"}
                    variant={"Contained"}
                    color={"Primary"}
                  >
                    저장
                  </Button>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
          )}
          <IonButton expand="full" onClick={this.onLogin}>
            로그인
          </IonButton>

          <div className="admin-item-container">
            <div className="admin-title">
              가입서비스: {UserTypeName[this.props.partner.userType]}
            </div>
          </div>

          <div className="admin-item-container">
            <div className="admin-title">ID : {this.props.partner.id}</div>
          </div>
          <div className="admin-item-container">
            <div className="admin-title">
              가입일 :{" "}
              {this.props.partner.createdAt &&
                getDateStringFromToday({ date: this.props.partner.createdAt })}
            </div>
          </div>
          <div className="admin-item-container">
            <div className="admin-title">회사이름 : </div>
            <IonInput
              class="admin-input"
              placeholder="회사이름"
              value={this.state.companyName}
              onIonChange={(e) =>
                this.setState({
                  companyName: e.detail.value,
                  edited: true,
                })
              }
            />
          </div>
          <div className="admin-item-container">
            <div className="admin-title">회사프로필이름 : </div>
            <IonInput
              class="admin-input"
              placeholder="회사이름"
              value={this.state.companyProfileName}
              onIonChange={(e) =>
                this.setState({
                  companyProfileName: e.detail.value,
                  edited: true,
                })
              }
            />
          </div>

          <div className="admin-item-container">
            <div className="admin-title">회사전화번호 : </div>
            <IonInput
              class="admin-input"
              placeholder="회사전화번호"
              value={this.state.companyPhone}
              onIonChange={(e) =>
                this.setState({
                  companyPhone: e.detail.value,
                  edited: true,
                })
              }
            />
          </div>
          {this.props.partner.userType == UserType.BIZ_ALLINONE ? (
            <div className="common-container-row-wrap  admin-item-container">
              <div className="admin-title">회사 종류: </div>
              <div
                className={
                  "admin-toggle" +
                  (this.state.companyType ==
                  CompanyType.HEALTH_FUNTIONAL_FOOD_COMPANY
                    ? " admin-toggle-selected"
                    : "")
                }
                onClick={() => {
                  this.setState({
                    edited: true,
                    companyType: CompanyType.HEALTH_FUNTIONAL_FOOD_COMPANY,
                  });
                }}
              >
                {CompanyTypeName[CompanyType.HEALTH_FUNTIONAL_FOOD_COMPANY]}
              </div>
              <div
                className={
                  "admin-toggle" +
                  (this.state.companyType == CompanyType.PHARMACEUTICAL_COMPANY
                    ? " admin-toggle-selected"
                    : "")
                }
                onClick={() => {
                  this.setState({
                    edited: true,
                    companyType: CompanyType.PHARMACEUTICAL_COMPANY,
                  });
                }}
              >
                {CompanyTypeName[CompanyType.PHARMACEUTICAL_COMPANY]}
              </div>
              <div
                className={
                  "admin-toggle" +
                  (this.state.companyType == CompanyType.DISTRIBUTOR
                    ? " admin-toggle-selected"
                    : "")
                }
                onClick={() => {
                  this.setState({
                    edited: true,
                    companyType: CompanyType.DISTRIBUTOR,
                  });
                }}
              >
                {CompanyTypeName[CompanyType.DISTRIBUTOR]}
              </div>
              <div
                className={
                  "admin-toggle" +
                  (this.state.companyType == CompanyType.CONSULT
                    ? " admin-toggle-selected"
                    : "")
                }
                onClick={() => {
                  this.setState({
                    edited: true,
                    companyType: CompanyType.CONSULT,
                  });
                }}
              >
                {CompanyTypeName[CompanyType.CONSULT]}
              </div>
              <div
                className={
                  "admin-toggle" +
                  (this.state.companyType == CompanyType.FINANCE
                    ? " admin-toggle-selected"
                    : "")
                }
                onClick={() => {
                  this.setState({
                    edited: true,
                    companyType: CompanyType.FINANCE,
                  });
                }}
              >
                {CompanyTypeName[CompanyType.FINANCE]}
              </div>
              <div
                className={
                  "admin-toggle" +
                  (this.state.companyType == CompanyType.TAX
                    ? " admin-toggle-selected"
                    : "")
                }
                onClick={() => {
                  this.setState({ edited: true, companyType: CompanyType.TAX });
                }}
              >
                {CompanyTypeName[CompanyType.TAX]}
              </div>
              <div
                className={
                  "admin-toggle" +
                  (this.state.companyType == CompanyType.FRENCHISE
                    ? " admin-toggle-selected"
                    : "")
                }
                onClick={() => {
                  this.setState({
                    edited: true,
                    companyType: CompanyType.FRENCHISE,
                  });
                }}
              >
                {CompanyTypeName[CompanyType.FRENCHISE]}
              </div>
              <div
                className={
                  "admin-toggle" +
                  (this.state.companyType == CompanyType.INTERIOR
                    ? " admin-toggle-selected"
                    : "")
                }
                onClick={() => {
                  this.setState({
                    edited: true,
                    companyType: CompanyType.INTERIOR,
                  });
                }}
              >
                {CompanyTypeName[CompanyType.INTERIOR]}
              </div>
              <div
                className={
                  "admin-toggle" +
                  (this.state.companyType == CompanyType.LAW
                    ? " admin-toggle-selected"
                    : "")
                }
                onClick={() => {
                  this.setState({ edited: true, companyType: CompanyType.LAW });
                }}
              >
                {CompanyTypeName[CompanyType.LAW]}
              </div>
              <div
                className={
                  "admin-toggle" +
                  (this.state.companyType == CompanyType.LABOR
                    ? " admin-toggle-selected"
                    : "")
                }
                onClick={() => {
                  this.setState({
                    edited: true,
                    companyType: CompanyType.LABOR,
                  });
                }}
              >
                {CompanyTypeName[CompanyType.LABOR]}
              </div>
              <div
                className={
                  "admin-toggle" +
                  (this.state.companyType == CompanyType.EMR
                    ? " admin-toggle-selected"
                    : "")
                }
                onClick={() => {
                  this.setState({ edited: true, companyType: CompanyType.EMR });
                }}
              >
                {CompanyTypeName[CompanyType.EMR]}
              </div>
              <div
                className={
                  "admin-toggle" +
                  (this.state.companyType == CompanyType.AUTOMATION
                    ? " admin-toggle-selected"
                    : "")
                }
                onClick={() => {
                  this.setState({
                    edited: true,
                    companyType: CompanyType.AUTOMATION,
                  });
                }}
              >
                {CompanyTypeName[CompanyType.AUTOMATION]}
              </div>
              <div
                className={
                  "admin-toggle" +
                  (this.state.companyType == CompanyType.INSURANCE
                    ? " admin-toggle-selected"
                    : "")
                }
                onClick={() => {
                  this.setState({
                    edited: true,
                    companyType: CompanyType.INSURANCE,
                  });
                }}
              >
                {CompanyTypeName[CompanyType.INSURANCE]}
              </div>
              <div
                className={
                  "admin-toggle" +
                  (this.state.companyType == CompanyType.SCHOLAR
                    ? " admin-toggle-selected"
                    : "")
                }
                onClick={() => {
                  this.setState({
                    edited: true,
                    companyType: CompanyType.SCHOLAR,
                  });
                }}
              >
                {CompanyTypeName[CompanyType.SCHOLAR]}
              </div>
              <div
                className={
                  "admin-toggle" +
                  (this.state.companyType == CompanyType.REAL_ASSET
                    ? " admin-toggle-selected"
                    : "")
                }
                onClick={() => {
                  this.setState({
                    edited: true,
                    companyType: CompanyType.REAL_ASSET,
                  });
                }}
              >
                {CompanyTypeName[CompanyType.REAL_ASSET]}
              </div>
              <div
                className={
                  "admin-toggle" +
                  (this.state.companyType == CompanyType.QUASI_DRUG
                    ? " admin-toggle-selected"
                    : "")
                }
                onClick={() => {
                  this.setState({
                    edited: true,
                    companyType: CompanyType.QUASI_DRUG,
                  });
                }}
              >
                {CompanyTypeName[CompanyType.QUASI_DRUG]}
              </div>
              <div
                className={
                  "admin-toggle" +
                  (this.state.companyType == CompanyType.EDUCATION
                    ? " admin-toggle-selected"
                    : "")
                }
                onClick={() => {
                  this.setState({
                    edited: true,
                    companyType: CompanyType.EDUCATION,
                  });
                }}
              >
                {CompanyTypeName[CompanyType.EDUCATION]}
              </div>
              <div
                className={
                  "admin-toggle" +
                  (this.state.companyType == CompanyType.LIFE
                    ? " admin-toggle-selected"
                    : "")
                }
                onClick={() => {
                  this.setState({
                    edited: true,
                    companyType: CompanyType.LIFE,
                  });
                }}
              >
                {CompanyTypeName[CompanyType.LIFE]}
              </div>
              <div
                className={
                  "admin-toggle" +
                  (this.state.companyType == CompanyType.ETC
                    ? " admin-toggle-selected"
                    : "")
                }
                onClick={() => {
                  this.setState({ edited: true, companyType: CompanyType.ETC });
                }}
              >
                {CompanyTypeName[CompanyType.ETC]}
              </div>
            </div>
          ) : (
            <div className="common-container-row-wrap  admin-item-container">
              <div className="admin-title">회사 종류: </div>
              <div
                className={
                  "admin-toggle" +
                  (this.state.companyType == CompanyType.PHARMACY
                    ? " admin-toggle-selected"
                    : "")
                }
                onClick={() => {
                  this.setState({
                    edited: true,
                    companyType: CompanyType.PHARMACY,
                  });
                }}
              >
                {CompanyTypeName[CompanyType.PHARMACY]}
              </div>

              <div
                className={
                  "admin-toggle" +
                  (this.state.companyType == CompanyType.HOSPITAL
                    ? " admin-toggle-selected"
                    : "")
                }
                onClick={() => {
                  this.setState({
                    edited: true,
                    companyType: CompanyType.HOSPITAL,
                  });
                }}
              >
                {CompanyTypeName[CompanyType.HOSPITAL]}
              </div>
              <div
                className={
                  "admin-toggle" +
                  (this.state.companyType == CompanyType.PHARMACEUTICAL_COMPANY
                    ? " admin-toggle-selected"
                    : "")
                }
                onClick={() => {
                  this.setState({
                    edited: true,
                    companyType: CompanyType.PHARMACEUTICAL_COMPANY,
                  });
                }}
              >
                {CompanyTypeName[CompanyType.PHARMACEUTICAL_COMPANY]}
              </div>
              <div
                className={
                  "admin-toggle" +
                  (this.state.companyType == CompanyType.DISTRIBUTOR
                    ? " admin-toggle-selected"
                    : "")
                }
                onClick={() => {
                  this.setState({
                    edited: true,
                    companyType: CompanyType.DISTRIBUTOR,
                  });
                }}
              >
                {CompanyTypeName[CompanyType.DISTRIBUTOR]}
              </div>
              <div
                className={
                  "admin-toggle" +
                  (this.state.companyType == CompanyType.ETC
                    ? " admin-toggle-selected"
                    : "")
                }
                onClick={() => {
                  this.setState({ edited: true, companyType: CompanyType.ETC });
                }}
              >
                {CompanyTypeName[CompanyType.ETC]}
              </div>
            </div>
          )}

          <div className="admin-item-container">
            <div className="admin-title">담당자이름 : </div>
            <IonInput
              class="admin-input"
              placeholder="담당자이름"
              value={this.state.userName}
              onIonChange={(e) =>
                this.setState({
                  userName: e.detail.value,
                  edited: true,
                })
              }
            />
          </div>

          <div className="admin-item-container">
            <div className="admin-title">담당자전화번호 : </div>
            <IonInput
              class="admin-input"
              placeholder="담당자전화번호"
              value={this.state.phone}
              onIonChange={(e) =>
                this.setState({
                  phone: e.detail.value,
                  edited: true,
                })
              }
            />
            <div style={{ marginLeft: "10px" }}>
              <IconButton
                size="M"
                variant="Outlined"
                color="Primary"
                iconName="Call"
                onClick={() => callPhone(this.state.phone)}
              />
            </div>
            <div style={{ marginLeft: "10px", marginRight: "10px" }}>
              <IconButton
                size="M"
                variant="Outlined"
                color="Primary"
                iconName="Comment"
                onClick={() => sendSMSMessage(this.state.phone)}
              />
            </div>
          </div>
          <div className="admin-item-container">
            <div className="admin-title">알림톡 수신 전화번호</div>
            <div className="common-container admin-editor common-scroll admin-margin-bottom">
              <IonInput
                class="admin-input"
                placeholder="알림톡 받을 담당자 전화번호"
                value={this.state.phoneNumbers}
                onIonChange={(e) =>
                  this.setState({
                    phoneNumbers: e.detail.value,
                    edited: true,
                  })
                }
              />
            </div>
          </div>
          {this.props.partner.userType == UserType.BIZ_ALLINONE && (
            <div className="admin-item-container">
              <div className="admin-title">
                약문약답 사용자ID : {this.state.usersId}
              </div>
            </div>
          )}
          <div className="admin-item-container">
            <div className="admin-title">주소 : </div>
            <IonInput
              class="admin-input"
              placeholder="주소"
              value={this.state.companyAddress}
              onIonChange={(e) =>
                this.setState({
                  companyAddress: e.detail.value,
                  edited: true,
                })
              }
            />
          </div>
          <div className="admin-item-container">
            <div className="admin-title">위치 : </div>
            <IonInput
              class="admin-input"
              placeholder="GPSX"
              type="number"
              value={
                this.state &&
                this.state.companyPosition &&
                this.state.companyPosition.x
                  ? this.state.companyPosition.x
                  : "0"
              }
              onIonChange={(e) =>
                this.setState({
                  companyPosition: {
                    ...this.state.companyPosition,
                    x: e.detail.value,
                  },
                  edited: true,
                })
              }
            />
            <IonInput
              class="admin-input"
              placeholder="GPSY"
              type="number"
              value={
                this.state &&
                this.state.companyPosition &&
                this.state.companyPosition.y
                  ? this.state.companyPosition.y
                  : "0"
              }
              onIonChange={(e) =>
                this.setState({
                  companyPosition: {
                    ...this.state.companyPosition,
                    y: e.detail.value,
                  },
                  edited: true,
                })
              }
            />
          </div>
          <div className="admin-item-container">
            <div className="admin-title">이메일 : </div>
            <IonInput
              class="admin-input"
              placeholder="이메일"
              value={this.state.email}
              onIonChange={(e) =>
                this.setState({
                  email: e.detail.value,
                  edited: true,
                })
              }
            />
          </div>
          <div className="admin-item-container">
            <div className="admin-title">홈페이지 : </div>
            <IonInput
              class="admin-input"
              placeholder="홈페이지"
              value={this.state.homepage}
              onIonChange={(e) =>
                this.setState({
                  homepage: e.detail.value,
                  edited: true,
                })
              }
            />
          </div>
          {this.props.partner.userType == UserType.BIZ_ALLINONE && (
            <div className="admin-item-container">
              <div className="admin-title">만료일 : </div>
              <IonInput
                class="admin-input"
                placeholder="만료일"
                value={this.state.expireAt}
                onIonChange={(e) =>
                  this.setState({
                    expireAt: e.detail.value,
                    edited: true,
                  })
                }
              />
            </div>
          )}
          <div className="admin-item-container">
            <div className="admin-title">
              사업자등록번호: {this.props.partner.companyRegiNum}
            </div>
          </div>
          <div className="admin-item-container">
            <div className="admin-title">
              사업자등록증 :
              {this.props.partner.companyRegiDoc ? (
                <a href={this.props.partner.companyRegiDoc.url}>다운로드</a>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="admin-item-container">
            <div className="admin-title">logo </div>
            <ImageViewNSelect
              base64={this.state.logoImageBase64}
              url={this.state.logoImageUrl}
              onChange={(e) => this.onlogoSelected(e)}
              filePath={this.props.filePath}
              imageSize={ImageSize.SMALL}
            />
          </div>
          <div className="admin-item-container">
            <div className="admin-title">업체소개</div>
            <div className="common-container admin-editor common-scroll admin-margin-bottom">
              <Textarea
                className="admin-text-area"
                minRows={3}
                maxRows={15}
                value={this.state.introductionText}
                onChange={(e) =>
                  this.setState({ introductionText: e.target.value })
                }
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            </div>
          </div>
          <div className="admin-item-container">
            <div className="admin-title">업체소개 html</div>
            <div className="common-container admin-editor common-scroll admin-margin-bottom">
              <HtmlComposer
                noFocus={true}
                mini={true}
                body={this.state.introductionHTML}
                onBodyChanged={(body) => {
                  this.setState({ introductionHTML: body, edited: true });
                }}
              />
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = (state: RootState) => ({
  filePath: state.board.filePath,
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  progressPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
});

const mapDispatchToProps = {
  //   logOutUser: () => actions.partner.logout(),
  //   refreshBoards: () => actions.board.refreshBoards(),
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllInOnePartnerManageProfile);
