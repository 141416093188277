import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonHeader,
  IonIcon,
  IonButtons,
  IonButton,
  IonContent,
  IonLabel,
  IonToolbar,
} from "@ionic/react";
import "./../../Admin.scss";
import { log, LogLevel } from "../../../utils/LogUtil";
import {
  JobOfferInfo,
  JobOfferStatus,
  JobOfferStatusName,
} from "../../../models/Model.JobPost";
import JobPostDetail, {
  OptionType,
} from "../../../components/templates/JobPostDetail";
import {
  UserWorkType,
  UserType,
  CompanyType,
} from "../../../models/Model.User";
import { MY_ADDRESS } from "./../../../config.json";
import { UIPopupType } from "../../../store/ui/types";
import { fetchAPI } from "../../../utils/APIUtil";
import * as API from "./../../../API.json";
import { getGlobal, GlobalKey } from "../../../utils/GlobalUtil";
import JobPostBizDetail from "../../../pages/JobPostBizDetail";
import { Static } from "../../../components/atoms/Layout";
import { getDateStringFromToday } from "../../../utils/TimeUtil";
import JobPostBizDetailTemplate from "../../../components/templates/JobPostBizDetail";
import { Attachment } from "../../../models/Model.Board";
type Props = RouteComponentProps<{ tab: string }> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    offer?: JobOfferInfo;
    offerId?: number;
    onBack: () => void;
    onDone?: (offer?: JobOfferInfo) => void;
  };

type State = {
  offer: JobOfferInfo;
};

const AdminAPI = {
  JOBPOST_POST_REQUEST: {
    method: "GET",
    path: "/admin/jobpost/auto/ready/",
    contentType: "application/json",
  },
};

class JobpostDetail extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      offer: null,
    };

    if (this.props.offerId) {
      this.props.waitingPopup.show();
      fetchAPI(
        API.JOBPOST_OFFER_GET,
        "" + this.props.offerId,
        null,
        null,
        getGlobal(GlobalKey.TOKEN)
      ).then((result) => {
        if (result && !result.error && result.data) {
          this.setState({ offer: result.data });
          this.props.waitingPopup.hide();
        } else {
          log(LogLevel.UI_EXCEPTION, "UserManageProfile Update failed", result);
        }
      });
    }
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.offerId &&
      (!prevProps || this.props.offerId != prevProps.offerId)
    ) {
      this.props.waitingPopup.show();
      fetchAPI(
        API.JOBPOST_OFFER_GET,
        "" + this.props.offerId,
        null,
        null,
        getGlobal(GlobalKey.TOKEN)
      ).then((result) => {
        if (result && !result.error && result.data) {
          this.setState({ offer: result.data });
          this.props.waitingPopup.hide();
        } else {
          log(LogLevel.UI_EXCEPTION, "UserManageProfile Update failed", result);
        }
      });
    }
  }

  onPostRequest = () => {
    this.props.waitingPopup.show();
    fetchAPI(
      AdminAPI.JOBPOST_POST_REQUEST,
      "" + this.props.offer.id,
      null,
      null,
      getGlobal(GlobalKey.TOKEN)
    ).then((result) => {
      this.props.waitingPopup.hide();
      if (this.props.onDone)
        this.props.onDone({
          ...this.props.offer,
          status: JobOfferStatus.SAVED,
        });
    });
  };

  setCompanyTypeTagColor = () => {
    let offer: JobOfferInfo = this.props.offer;
    if (this.state.offer) offer = this.state.offer;

    switch (offer.companyType) {
      case CompanyType.HOSPITAL:
        return "Blue";
      case CompanyType.PHARMACEUTICAL_COMPANY:
        return "Green";
      case CompanyType.DISTRIBUTOR:
        return "Brown";
      case CompanyType.ETC:
      default:
        return "Gray";
    }
  };

  render() {
    log(LogLevel.UI_LIFECYCLE, "JobpostDetail.render", this.props, this.state);

    let offer: JobOfferInfo = this.props.offer;
    if (this.state.offer) offer = this.state.offer;
    if (!offer) return <div />;

    let viewer = (
      <JobPostBizDetailTemplate
        me={this.props.me}
        isOwner={false}
        post={offer}
        handleGoBackClick={() => this.props.onBack()}
        setCompanyTypeTagColor={this.setCompanyTypeTagColor}
        isPrivate={true}
        handleToggleScrap={() => {}}
        handleDownloadClick={(file: Attachment) => () => {}}
        handleOpenBottomSheet={() => {}}
        handleCloseBottomSheet={() => {}}
        handleShareClick={() => {}}
        handleSelectOption={(optionType: OptionType) => () => {}}
        handleLinkClick={(type: any, url?: string) => () => {}}
      />
    );
    return (
      <>
        <IonHeader class="setting-header" no-border>
          <IonToolbar color="setting-toolbar">
            <IonButtons slot="start">
              <IonButton
                class="setting-toolbar-button"
                onClick={this.props.onBack}
              >
                <IonIcon name="arrow-back" mode="ios" />
              </IonButton>
              <IonLabel onClick={this.props.onBack}>설정</IonLabel>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton
                class="setting-toolbar-button"
                onClick={() => {
                  window.open(
                    MY_ADDRESS + "/jobpost/view?id=" + offer.id,
                    "_blank"
                  );
                }}
              >
                이동
              </IonButton>

              {this.props.offer &&
                this.props.offer.ref &&
                [
                  JobOfferStatus.AUTO_CREATED ||
                    JobOfferStatus.AUTO_POST_WAITING ||
                    JobOfferStatus.AUTO_CREATING,
                ].includes(this.props.offer.status) && (
                  <IonButton
                    class="setting-toolbar-button"
                    onClick={() => {
                      this.onPostRequest();
                    }}
                  >
                    등록 대기(메일발송)
                  </IonButton>
                )}
              {this.props.offer && this.props.offer.ref && (
                <IonButton
                  class="setting-toolbar-button"
                  onClick={() => {
                    window.open(
                      `https://recruit.dailypharm.com/Main/Offer/OfferView.php?ID=${this.props.offer.ref}`,
                      "_blank"
                    );
                  }}
                >
                  오리지널공고 보기
                </IonButton>
              )}
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <Static>
            ID: {offer.id}
            <br />
            회사명: {offer.companyName}
            <br />
            제목: {offer.title}
            <br />
            상태 : {JobOfferStatusName[offer.status]}
            <br />
            시작일: {getDateStringFromToday({ date: offer.createdAt })}
            <br />
            종료일:{" "}
            {offer.endedAt && getDateStringFromToday({ date: offer.endedAt })}
            <br />
          </Static>
          {viewer}4
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  waitingPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
  me: state.user.me,
});

const mapDispatchToProps = {};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(JobpostDetail)
);
