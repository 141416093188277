import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonHeader,
  IonIcon,
  IonButtons,
  IonButton,
  IonContent,
  IonLabel,
  IonToolbar,
} from "@ionic/react";
import "./../../Admin.scss";
import { log, LogLevel } from "../../../utils/LogUtil";
import {
  JobOfferInfo,
  JobOfferStatusName,
} from "../../../models/Model.JobPost";
import JobPostDetail from "../../../components/templates/JobPostDetail";
import { UserWorkType, UserType } from "../../../models/Model.User";
import { MY_ADDRESS } from "../../../config.json";
import { UIPopupType } from "../../../store/ui/types";
import { fetchAPI } from "../../../utils/APIUtil";
import * as API from "../../../API.json";
import { getGlobal, GlobalKey } from "../../../utils/GlobalUtil";
import JobPostBizDetail from "../../../pages/JobPostBizDetail";
import { Static } from "../../../components/atoms/Layout";
import { getDateStringFromToday } from "../../../utils/TimeUtil";
import JobPostBizRegistration, {
  defaultRegisterFormErrors,
  RegisterFormErrors,
} from "../../../components/templates/JobPostBizRegistration";
type Props = RouteComponentProps<{ tab: string }> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    offer?: JobOfferInfo;
    onChange: (offer: JobOfferInfo) => void;
    onBack: () => void;
    onSave: (offer?: JobOfferInfo) => void;
  };

type State = {
  errors: RegisterFormErrors;
};

class JobpostDetail extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errors: { ...defaultRegisterFormErrors },
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.offer &&
      (!prevProps || this.props.offer.id != prevProps.offer.id)
    ) {
      let offer = { ...this.props.offer };
      this.setState({ errors: { ...defaultRegisterFormErrors } });
      this.props.onChange(offer);
    }
  }

  save = async () => {
    log(LogLevel.UI_EVENT, "JobpostDetail.save", this.props.offer);
    this.props.waitingPopup.show();
    await fetchAPI(
      API.JOBPOST_OFFER_UPDATE,
      "",
      null,
      this.props.offer,
      getGlobal(GlobalKey.TOKEN)
    );
    this.props.onSave(this.props.offer);
    this.props.waitingPopup.hide();
  };

  render() {
    log(LogLevel.UI_LIFECYCLE, "JobpostDetail.render", this.props, this.state);

    if (!this.props.offer) return <div />;

    return (
      <>
        <IonHeader class="setting-header" no-border>
          <IonToolbar color="setting-toolbar">
            <IonButtons slot="start">
              <IonButton
                class="setting-toolbar-button"
                onClick={this.props.onBack}
              >
                <IonIcon name="arrow-back" mode="ios" />
              </IonButton>
              <IonLabel onClick={this.props.onBack}>설정</IonLabel>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton
                class="setting-toolbar-button"
                onClick={() => {
                  this.save();
                }}
              >
                저장
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <Static>
            ID: {this.props.offer.id}
            <br />
            회사명: {this.props.offer.companyName}
            <br />
            제목: {this.props.offer.title}
            <br />
            상태 : {JobOfferStatusName[this.props.offer.status]}
            <br />
            시작일:{" "}
            {getDateStringFromToday({ date: this.props.offer.createdAt })}
            <br />
            ref : {this.props.offer.ref}
            <br />
          </Static>
          <JobPostBizRegistration
            offer={this.props.offer}
            onBack={this.props.onBack}
            errors={this.state.errors}
            onChange={(offer: JobOfferInfo) => {
              this.props.onChange({ ...this.props.offer, ...offer });
            }}
            onSave={() => {}}
          />
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  waitingPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
});

const mapDispatchToProps = {};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(JobpostDetail)
);
