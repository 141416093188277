import * as events from "./actions";
import { EventState, EventActionTypes } from "./types";
import { ActionType, getType } from "typesafe-actions";
import { Middleware } from "redux";
import { fetchAPI } from "../../utils/APIUtil";
import * as API from "./../../API.json";
import {
  getGlobal,
  GlobalKey,
  setGlobal,
  clearGlobal,
} from "./../../utils/GlobalUtil";
import { EventInfo } from "../../models/Model.Event";
import { ErrorCode } from "../../models/ErrorCode";
import { log, LogLevel } from "../../utils/LogUtil";
import { UserState } from "../user/types";
import { conditionCheck } from "../../utils/ConditionCheckerUtil";

export const fetchEventMiddleware: Middleware<{}, EventState> = (store) => (
  next
) => async (action: ActionType<typeof events>) => {
  next(action);

  let states = store.getState();
  let state: EventState = states["event"];
  let today = Math.floor(
    (Date.now() + 9 * 60 * 60 * 1000) / (24 * 60 * 60 * 1000)
  );
  if (action.type == getType(events.getEvents)) {
    log(
      LogLevel.REDUX_BOARD,
      "fetchEventMiddleware : events.getEvents ",
      action,
      today
    );
    let lastEvents = getGlobal(GlobalKey.EVENT_LIST, true);
    let lastEventList: EventInfo[] = [];
    if (lastEvents) {
      lastEventList = Object.keys(lastEvents).map((key) => lastEvents[key]);
    }
    next(events.fetchEvents.request());
    try {
      fetchAPI(API.EVENT_GET, "", null, null, getGlobal(GlobalKey.TOKEN))
        .then((result) => {
          log(
            LogLevel.REDUX_BOARD,
            "fetchEventMiddleware : events.getEvents result",
            result
          );
          if (result && !result.error) {
            let props = { me: states["user"].me };
            let eventLists = result.data.filter((item) =>
              conditionCheck(item.condition, props)
            );
            log(
              LogLevel.REDUX_BOARD,
              "fetchEventMiddleware : events.getEvents filtered",
              eventLists,
              props,
              states["user"]
            );
            let revents: EventInfo[] = eventLists;
            let newEvents = {};
            for (let i = 0; i < revents.length; i++) {
              if (lastEvents && lastEvents[revents[i].id]) {
                revents[i].ignoredAt = lastEvents[revents[i].id].ignoredAt;
                if (
                  revents[i].ignoredAt &&
                  (!revents[i].ignoreDuration ||
                    today <= revents[i].ignoredAt + revents[i].ignoreDuration)
                ) {
                  revents[i].notToday = true;
                }
              }

              newEvents[revents[i].id] = revents[i];
            }
            setGlobal(GlobalKey.EVENT_LIST, newEvents, true);
            log(
              LogLevel.REDUX_BOARD,
              "fetchEventMiddleware : getEvents success",
              lastEvents,
              revents,
              newEvents,
              events.fetchEvents
            );
            next(events.fetchEvents.success(revents));
          } else {
            log(
              LogLevel.REDUX_BOARD,
              "fetchEventMiddleware : getEvents1 failed",
              result
            );
            clearGlobal(GlobalKey.EVENT_LIST, true);
            let error: Error = {
              name: "error code " + result.error,
              message: "error code " + result.error,
            };
            next(events.fetchEvents.failure(error));
          }
        })
        .catch((e) => {
          log(
            LogLevel.REDUX_BOARD,
            "fetchEventMiddleware getEvents2 failed",
            e
          );
          next(events.fetchEvents.failure(e));
        });
    } catch (e) {
      log(LogLevel.REDUX_BOARD, "fetchEventMiddleware getEvents3 failed", e);
      next(events.fetchEvents.failure(e));
    }
  } else if (action.type == getType(events.ignoreEvent)) {
    let events = getGlobal(GlobalKey.EVENT_LIST, true);
    events[action.payload].ignoredAt = today;
    events[action.payload].notToday = true;
    setGlobal(GlobalKey.EVENT_LIST, events, true);
    log(
      LogLevel.REDUX_BOARD,
      "fetchEventMiddleware : events.ignoreEvent ",
      action,
      state,
      events
    );
  }
};
