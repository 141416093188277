import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonInput,
  IonText,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonIcon,
  IonButtons,
  IonMenuButton,
  IonList,
  IonSegmentButton,
  IonButton,
  IonSearchbar,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonFab,
  IonFabList,
  IonFabButton,
  IonAlert,
  IonBadge,
  IonLabel,
} from "@ionic/react";
import "./Admin.scss";
import { log, LogLevel } from "../utils/LogUtil";
import { setGlobal, GlobalKey, getGlobal } from "../utils/GlobalUtil";
import { fetchAPI } from "../utils/APIUtil";
import Truncate from "react-truncate";
import {
  SeminarLecture,
  SeminarLectureAttribute,
} from "../models/Model.Seminar";
import { UIPopupType } from "../store/ui/types";
import { STATUS_CODES } from "http";
import {
  PollInfo,
  PollItemInfo,
  PollResponseInfo,
  PollResponseItemInfo,
} from "../models/Model.Poll";
import PollSelect from "./component/poll/PollSelect";
import { idText } from "typescript";
import { JobApplyInfo, JobOfferInfo } from "../models/Model.JobPost";
import JobpostList from "./component/bizjobpost/JobpostList";
import JobpostDetail from "./component/bizjobpost/JobpostDetail";
import JobpostCandidateList from "./component/bizjobpost/JobpostCandidateList";
import { showBottomSheet } from "../store/modal/bottomsheet";
import ToggleButton from "../components/atoms/ToggleButton/ToggleButton";
import JobPostBizRegistration, {
  defaultRegisterFormErrors,
  RegisterFormErrors,
} from "../components/templates/JobPostBizRegistration";
import JobpostEdit from "./component/bizjobpost/JobpostEdit";

type Props = RouteComponentProps<{ tab: string }> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

type State = {
  offer: JobOfferInfo;
  offerEdit: JobOfferInfo;
  showList: boolean;
  showDetail: boolean;
  showEdit: boolean;
  errors: RegisterFormErrors;
};

class BizJobpostMigrationManage extends Component<Props, State> {
  list = null;
  state = {
    offer: null,
    offerEdit: null,
    showList: true,
    showDetail: false,
    showEdit: false,
    errors: { ...defaultRegisterFormErrors },
  };

  constructor(props: Props) {
    super(props);
    document.title = "법인 구인공고 이관 관리";
  }

  componentDidMount() {}

  onSelectOffer = (offer: JobOfferInfo) => {
    if (!this.state.offerEdit || this.state.offerEdit.id != offer.id)
      this.setState({ offerEdit: { ...offer } });
    this.setState({ offer, showDetail: true });
  };

  onUnselect = () => {
    this.setState({
      offer: null,
      showDetail: false,
      showEdit: false,
      showList: true,
    });
  };

  onSave = (offer?: JobOfferInfo) => {
    log(
      LogLevel.UI_EVENT,
      "BizJobpostMigrationManage.onSave",
      offer,
      this.list
    );
    this.setState({
      offerEdit: { ...this.state.offerEdit, ...offer },
    });

    if (this.list) this.list.refresh(offer);
  };

  render() {
    log(
      LogLevel.UI_LIFECYCLE,
      "BizJobpostMigrationManage.render",
      this.props,
      this.state
    );

    return (
      <>
        <IonHeader class="admin-header" no-border>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonText slot="middle">법인 구인공고 이관 관리</IonText>
          <IonButtons slot="end">
            <ToggleButton
              onClick={() =>
                this.setState({
                  showList: !this.state.showList,
                })
              }
              size={"Small"}
              type={"Text"}
              variant={"Tinted"}
              color={"Skyblue"}
              style={{ marginRight: "10px" }}
              active={this.state.showList}
            >
              공고 목록
            </ToggleButton>
            <ToggleButton
              onClick={() =>
                this.setState({ showDetail: !this.state.showDetail })
              }
              size={"Small"}
              type={"Text"}
              variant={"Tinted"}
              color={"Skyblue"}
              style={{ marginRight: "10px" }}
              active={this.state.showDetail && this.state.offer}
              disabled={!this.state.offer}
              disabledColor={!this.state.offer}
            >
              공고 상세
            </ToggleButton>
            <ToggleButton
              onClick={() => {
                this.setState({ showEdit: !this.state.showEdit });

                if (
                  !this.state.offerEdit ||
                  this.state.offerEdit.id != this.state.offer.id
                ) {
                  this.setState({ offerEdit: { ...this.state.offer } });
                }
              }}
              size={"Small"}
              type={"Text"}
              variant={"Tinted"}
              color={"Skyblue"}
              style={{ marginRight: "10px" }}
              active={this.state.showEdit}
              disabled={!this.state.offer}
              disabledColor={!this.state.offer}
            >
              수정창
            </ToggleButton>
          </IonButtons>
        </IonHeader>

        <IonContent>
          <div className="admin-main-container">
            {this.state.showList && (
              <div className="admin-grow-container">
                <JobpostCandidateList
                  onSelectOffer={this.onSelectOffer}
                  ref={(ref) => (this.list = ref)}
                />
              </div>
            )}
            {this.state.showDetail && (
              <div className="admin-container">
                <JobpostDetail
                  offer={this.state.offerEdit || this.state.offer}
                  onBack={this.onUnselect}
                  onDone={this.onSave}
                />
              </div>
            )}

            {this.state.showEdit && this.state.offerEdit && this.state.offer && (
              <div className="admin-container">
                <JobpostEdit
                  offer={this.state.offerEdit}
                  onBack={this.onUnselect}
                  onChange={(offer: JobOfferInfo) => {
                    this.setState({
                      offerEdit: { ...this.state.offerEdit, ...offer },
                    });
                  }}
                  onSave={this.onSave}
                />
              </div>
            )}
          </div>
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  lectures: state.seminar.lectures,
  progressPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  filePath: state.board.filePath,
});

const mapDispatchToProps = {
  //   logOutUser: () => actions.user.logout(),
  //   refreshBoards: () =>    actions.board.refreshBoards(),
  loadLecture: (lectureId: number) =>
    actions.seminar.getSeminarLecture(lectureId),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BizJobpostMigrationManage)
);
