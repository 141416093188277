import React, { Component, RefObject } from "react";
import {
  IonButton,
  IonInput,
  IonIcon,
  IonToast,
  IonContent,
} from "@ionic/react";
import { fetchAPI } from "../../../utils/APIUtil";
import * as API from "../../../API.json";
import { UserInfo, UserWorkType } from "../../../models/Model.User";
import { getGlobal, GlobalKey } from "../../../utils/GlobalUtil";
import { log, LogLevel } from "../../../utils/LogUtil";
import { loadImageBase64 } from "../../../utils/ImageUtil";
import { RootState } from "../../../store";
import { connect } from "react-redux";
import { UIPopupType } from "../../../store/ui/types";
import AnalyticsUtil from "../../../utils/AnalyticsUtil";
import StringUtil from "../../../utils/StringUtil";
import { Absolute, Fixed, Flex, Header, Relative } from "../../atoms/Layout";
import TopAppBar from "../../molecules/TopAppBar/TopAppBar";
import Button from "../../atoms/Button";
import Body from "../../cell/Body";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";
import { on } from "cluster";
import Input from "../../atoms/Input";

type Props = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    onDone: (data: UserInfo) => void;
    onBack: (data: UserInfo) => void;
    onInquiry: () => void;
    licenseCheckOnly?: boolean;
    user?: UserInfo;
  };

type State = {
  license: string;
  licenseImageBase64: any;
};
const windowAny: any = window;

class RegisterStageLicenseCheck extends Component<Props, State> {
  fileInputRef: any;
  constructor(props) {
    super(props);
    this.state = {
      licenseImageBase64: "",
      license:
        this.props.user &&
        this.props.user.workType != UserWorkType.UNDERGRADUATE
          ? this.props.user.license
          : "",
    };
    this.fileInputRef = React.createRef();
  }

  canPass = () => {
    return this.state.licenseImageBase64 && this.state.license;
  };

  onDone = () => {
    if (!this.state.license) {
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "LICENSE_CHECK_FAIL",
        "약사인증_개인정보입력실패",
        {
          이유: "면허번호이상",
        }
      );
      this.props.toast.show("면허번호을 입력하셔야합니다.");
      return;
    }
    if (!this.state.licenseImageBase64) {
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "LICENSE_CHECK_FAIL",
        "약사인증_개인정보입력실패",
        {
          이유: "면허사진이상",
        }
      );
      this.props.toast.show("면허사진을 추가하셔야합니다.");
      return;
    }

    let rvalue: UserInfo = {
      licenseImageBase64: this.state.licenseImageBase64,
      license: this.state.license,
    };
    this.props.onDone(rvalue);
  };

  onBack = () => {
    let rvalue = {
      licenseImageBase64: this.state.licenseImageBase64,
    };
    this.props.onBack(rvalue);
  };

  onChat = async () => {
    let os = getGlobal(GlobalKey.OS);
    if (!os || os == "browser") {
      try {
        windowAny.Kakao.init("0d4139a6dc131b05b8109f629d7cc3f7");
      } catch (e) {
        log(LogLevel.UI_EXCEPTION, e);
      }
      try {
        windowAny.Kakao.PlusFriend.chat({
          plusFriendId: "_Vxjhxgj", // 플러스친구 홈 URL에 명시된 id로 설정합니다.
        });
      } catch (e) {
        log(LogLevel.UI_EXCEPTION, e);
      }
    } else {
      let plusFriendTemplate = {
        plusFriendId: "_Vxjhxgj",
      };
      try {
        await windowAny.KakaoCordovaSDK.chatPlusFriend(plusFriendTemplate);
      } catch (e) {
        log(LogLevel.UI_EXCEPTION, e);
      }
    }
  };

  onImageSelect = () => {
    if (this.fileInputRef.current) {
      this.fileInputRef.current.click();
    }
  };

  onImageSelected = (e) => {
    // console.dir(e);
    console.dir(e.target.files[0]);
    console.dir(e.target);
    if (e.target.files && e.target.files[0]) {
      loadImageBase64(e.target.files[0]).then((base64) => {
        this.onImageLoad(base64);
      });
    }
  };

  onImageLoad = (base64) => {
    this.setState({ licenseImageBase64: base64 });
  };

  render() {
    log(LogLevel.UI_LIFECYCLE, "RegisterStageLicenseCheck:render");

    return (
      <>
        <Header>
          <TopAppBar
            title=""
            titleType="H4"
            leftButtons={[
              <Button
                color="Tertiary"
                icon="CaretLeft"
                onClick={this.props.onBack}
                size="Medium"
                type="Icon"
                variant="Ghost"
              />,
            ]}
          />
        </Header>
        <Body scrollable={true}>
          <Relative
            customStyle={{
              position: "relative",
              padding: "20px 24px",
              paddingBottom: "80px",
            }}
          >
            <Flex
              direction="column"
              gap="8px"
              customStyle={{ marginBottom: "40px" }}
            >
              <Text textType="H1" color={COLOR_SYSTEM.get("Gray")[900]}>
                약사 인증을 위해 <br />
                약사 면허증 또는
                <br /> 면허자격 증명서 사진이 필요해요
                <br />
              </Text>
              {this.props.licenseCheckOnly && (
                <Input
                  label="면허번호"
                  required={true}
                  hint="면허번호는 암호화되어 식별불가하게 보관됩니다."
                  style={{ margin: "16px 0px" }}
                  onChange={(e) => {
                    this.setState({ license: e.target.value });
                  }}
                  value={this.state.license}
                  blurOnEnter={true}
                />
              )}
              <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[700]}>
                아래와 같이{" "}
                <Text textType="Body1Bold" color={COLOR_SYSTEM.get("Red")[400]}>
                  면허번호와 성명이 보이는
                </Text>{" "}
                면허증 또는 면허자격 증명서를 첨부해주세요. <br />
                실명, 면허번호를 사진과 대조하여 일치할 경우에만 가입이
                승인됩니다.
                <br />
              </Text>
              <Text textType="Body2" color={COLOR_SYSTEM.get("Skyblue")[400]}>
                *면허증 사진은 가입 승인 즉시 폐기됩니다.
              </Text>
            </Flex>
            {this.state.licenseImageBase64 ? (
              <img
                src={this.state.licenseImageBase64}
                style={{ width: "100%", height: "auto" }}
              />
            ) : (
              <img
                src="/images/license-sample.jpg"
                style={{ width: "100%", height: "auto" }}
                onClick={this.onImageSelect}
              />
            )}

            {this.state.licenseImageBase64 ? (
              <Flex
                alignItems="center"
                justifyContent="space-between"
                gap="12px"
                customStyle={{
                  marginTop: "20px",
                  width: "100%",
                  padding: "16px 0px",
                  backgroundColor: "white",
                  zIndex: 100,
                }}
              >
                <Button
                  size={"Large"}
                  type={"Text"}
                  variant={"Outlined"}
                  color={"Quaternary"}
                  style={{ flex: 1 }}
                  onClick={this.onImageSelect}
                >
                  사진 다시 선택
                </Button>
                <Button
                  size={"Large"}
                  type={"Text"}
                  variant={"Contained"}
                  color={"Primary"}
                  style={{ flex: 1 }}
                  onClick={this.onDone}
                  disabledColor={!this.canPass()}
                >
                  {this.props.licenseCheckOnly ? "완료" : "다음"}
                </Button>
              </Flex>
            ) : (
              <Flex
                customStyle={{
                  width: "100%",
                  padding: "16px 0px",
                  marginTop: "20px",
                  zIndex: 100,
                }}
              >
                <Button
                  size={"Large"}
                  type={"Text"}
                  variant={"Contained"}
                  color={"Primary"}
                  style={{ width: "100%" }}
                  onClick={this.onImageSelect}
                >
                  면허증 첨부하기
                </Button>
              </Flex>
            )}

            <Absolute top="20px" right="24px">
              <Button
                color="Quaternary"
                size="Small"
                type="Text"
                variant="Outlined"
                style={{ flex: 1 }}
                onClick={() => this.props.onInquiry()}
              >
                문의하기
              </Button>
            </Absolute>
            <input
              type="file"
              accept="image/*"
              multiple={false}
              ref={this.fileInputRef}
              style={{ display: "none" }}
              onChange={this.onImageSelected}
            />
          </Relative>
        </Body>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  filePath: state.board.filePath,
  toast: state.ui.popups[UIPopupType.TOAST_POPUP],
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterStageLicenseCheck);
