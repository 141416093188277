import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Button from "../../atoms/Button";
import Text from "../../atoms/Text";
import pin from "../../../public/images/pin.png";
// import NoCompanyLogoImage from "../../../public/images/no_company_logo.png";
import { useDispatch } from "react-redux";
import { COLOR_SYSTEM } from "../../design/design-system";
import { SHADOW } from "../../design/effects";
import { BizUserInfo } from "../../../models/Mode.Biz";
import { CompanyTypeName } from "../../../models/Model.User";
import Input from "../../atoms/Input";
import CheckBox from "../../atoms/CheckBox/CheckBox";
import {
  JobOfferInfo,
  JobOfferSalaryType,
  JobOfferType,
} from "../../../models/Model.JobPost";
import SearchAddressPopup from "../../popup/SearchAddressPopup";
import SearchAddress from "../../SearchAddress";
import WysiwygEditor from "../../atoms/WysiwygEditor";
import ToggleButton from "../../atoms/ToggleButton/ToggleButton";

interface RegisterStyledProps {
  withLabel?: boolean;
}

export enum EmployeeCnt {
  NONE = 0,
  FIFTY = "50",
  HUNDRED = "100",
  FIFTY_HUNDRED = "500",
  GREATER_FIFTY_HUNDRED = "501",
}

const employeeCntParser = {
  [EmployeeCnt.NONE]: "",
  [EmployeeCnt.FIFTY]: "50명 이하",
  [EmployeeCnt.HUNDRED]: "51~100명",
  [EmployeeCnt.FIFTY_HUNDRED]: "101~500명",
  [EmployeeCnt.GREATER_FIFTY_HUNDRED]: "501명 이상",
};

export enum ApplyMethod {
  Chat = "C",
  Message = "M",
  ALL = "CM",
  Email = "E",
  Post = "P",
  Homepage = "H",
  None = "",
}

export interface RegisterFormErrors {
  introduction: boolean;
  namePersonInCharge: boolean;
  emailPersonInCharge: boolean;
  phonePersonInCharge: boolean;
  applyMethod: boolean;
  applyMethodEmail: boolean;
  applyMethodHomepage: boolean;
  applyMethodPost: boolean;
  applyDate: boolean;
  hireNotice: boolean;
  title: boolean;
  workType: boolean;
  salary: boolean;
  salaryLow: boolean;
  salaryHigh: boolean;
  address: boolean;
  message: boolean;
  qualification: boolean;
}

export const defaultRegisterFormErrors: RegisterFormErrors = {
  introduction: false,
  namePersonInCharge: false,
  emailPersonInCharge: false,
  phonePersonInCharge: false,
  applyMethod: false,
  applyMethodEmail: false,
  applyMethodHomepage: false,
  applyMethodPost: false,
  applyDate: false,
  hireNotice: false,
  title: false,
  workType: false,
  salary: false,
  salaryLow: false,
  salaryHigh: false,
  address: false,
  message: false,
  qualification: false,
};

const RegisterFormLayout = styled.div`
  width: 100%;
  padding: 0 40px;
  margin: 0px auto 40px;

  .company-info {
    margin-bottom: 50px;
    display: flex;
    gap: 48px;

    .info__logo {
      width: 160px;
      height: 160px;
      border: 1px solid ${COLOR_SYSTEM.get("Gray")[50]};
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }

    .info__description {
      display: flex;
      flex-direction: column;
      gap: 9px;

      .description__item {
        display: flex;
        align-items: center;

        .description__title {
          width: 120px;
        }
      }
    }
  }

  .register__description {
    display: block;
    margin-top: 100px;
    margin-bottom: 60px;
  }

  .register__address {
    & > * + * {
      margin-top: 8px;
    }
  }

  .register__employer-info {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;

    div {
      flex: 1 1 45%;
    }
  }

  .register__probation-period {
    display: flex;
    gap: 32px;

    & > * {
      flex: 1;
    }

    & > .disabled {
      opacity: 0.3;

      & * {
        cursor: default;
        border: none;
      }

      & > label > .label__main > span {
        color: ${COLOR_SYSTEM.get("Gray")[600]};
      }
    }
  }

  .register__workplace {
    margin-top: 60px;

    label {
      margin-bottom: 12px;
    }
  }
`;

export const InputField = styled.div`
  & > * + * {
    margin-top: 40px;
  }

  .field__title {
    display: flex;
    align-items: flex-start;
    margin-bottom: 12px;

    & > * + * {
      margin-left: 4px;
    }

    &.field-description {
      flex-direction: column;

      & > * + * {
        margin-left: 0px;
      }
    }
  }

  .field__checkbox-list {
    display: flex;
    flex-direction: column;
    gap: 25px;

    .checkbox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 40px;

      & > label {
        width: 160px;
      }

      .checkbox__input {
        width: 540px;
        position: relative;
      }

      &.multi-input {
        label {
          align-self: flex-start;
        }
        .checkbox__input > * + * {
          margin-top: 8px;
        }
      }
    }
  }

  .register__date {
    .field__range {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      .apply-date__checkbox {
        margin-left: 24px;
      }

      .salary-input {
        margin-bottom: 20px;
      }

      .range__input-field {
        display: flex;
        align-items: center;
        gap: 12px;

        &.disabled {
          opacity: 0.3;

          & * {
            cursor: default;
            border: none;
          }
        }

        .salary-range__tilde {
          align-self: flex-start;
          position: relative;
          top: 12px;
        }
      }

      label {
        align-self: flex-start;
        position: relative;
        top: 12px;
      }
    }
  }

  .register__salary {
    .field__range {
      display: flex;
      flex-direction: column;

      .salary-input {
        margin-bottom: 20px;

        .radio__title {
        }
      }

      .range__input-field {
        margin-left: 40px;
        margin-top: 26px;
        width: 80%;
        display: flex;
        align-items: center;
        gap: 12px;

        &.disabled {
          opacity: 0.3;

          & * {
            cursor: default;
            border: none;
          }
        }

        .salary-range__tilde {
          align-self: flex-start;
          position: relative;
          top: 12px;
        }

        .dropdown {
          align-self: flex-start;
        }
      }

      label {
        align-self: flex-start;
        position: relative;
        top: 12px;
      }
    }
  }
`;

const PreviewContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
`;

export const UploadedFiles = styled.div`
  margin-top: 32px;

  & > * + * {
    margin-top: 8px;
  }
`;

export const File = styled.div`
  padding: 12px 24px 12px 12px;
  background-color: ${COLOR_SYSTEM.get("Gray")[10]};
  border: 1px solid ${COLOR_SYSTEM.get("Gray")[50]};
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  .file-icon {
    width: 48px;
    height: 48px;
    background-color: ${COLOR_SYSTEM.get("Skyblue")[50]};
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .file-info {
    flex: 1;
  }
`;

export const AddressSearchList = styled.ul<RegisterStyledProps>`
  padding: 20px 0;
  margin: 0;
  width: 100%;
  height: 600px;
  background-color: ${COLOR_SYSTEM.get("Gray")[0]};
  position: absolute;
  top: ${({ withLabel }) => (withLabel ? "78px" : "48px")};
  z-index: 100;
  border-radius: 20px;
  overflow: auto;
  ${SHADOW.get("4")}

  li {
    list-style: none;

    button {
      width: 100%;
      padding: 20px 24px;
      display: flex;
      align-items: center;
      gap: 20px;
      border: none;
      background-color: ${COLOR_SYSTEM.get("Gray")[0]};
      cursor: pointer;

      &:hover {
        background-color: ${COLOR_SYSTEM.get("Gray")[10]};
      }

      .address {
        flex: 1;
        text-align: left;
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  position: sticky;
  bottom: 0;
  width: 920px;
  margin-top: 100px;
  padding: 24px 0;
  background-color: ${COLOR_SYSTEM.get("Gray")[0]};
  display: flex;
  z-index: 101;

  & > * + * {
    margin-left: 32px;
  }

  button {
    flex: 1 1 33%;
  }
`;

const SalaryTypeItem = styled.li`
  padding: 12px 20px;
  cursor: pointer;

  &:hover,
  &.active {
    background-color: ${COLOR_SYSTEM.get("Gray")[10]};
  }
`;

interface IProps {
  offer: JobOfferInfo;
  errors: RegisterFormErrors;
  isLoading?: boolean;
  onChange: (offer: JobOfferInfo) => void;
  onSave: () => void;
  onBack: () => void;
}

const JobPostBizRegistration: React.FC<IProps> = ({
  offer,
  errors,
  isLoading,
  onChange,
  onBack,
}) => {
  const dispatch = useDispatch();
  const [dropdownActive, setDropdownActive] = useState({
    salary: false,
  });

  const [showAddressPopup, setShowAddressPopup] = useState(0); // 0: 안보임, 1: 주소 검색, 2: 지원주소

  const logoEl = useRef<HTMLInputElement>(null);
  const fileUploadEl = useRef<HTMLInputElement>(null);
  const posterImageUploadEl = useRef<HTMLInputElement>(null);
  const introductionField = useRef<HTMLDivElement>(null);
  const personInChargeField = useRef<HTMLDivElement>(null);
  const applyMethodField = useRef<HTMLDivElement>(null);
  const applyDateField = useRef<HTMLDivElement>(null);

  const hireNoticeField = useRef<HTMLDivElement>(null);
  const titleField = useRef<HTMLDivElement>(null);
  const workTypeField = useRef<HTMLDivElement>(null);
  const salaryField = useRef<HTMLDivElement>(null);
  const addressField = useRef<HTMLDivElement>(null);
  const messageField = useRef<HTMLDivElement>(null);
  const qualificationField = useRef<HTMLDivElement>(null);

  const getDateAfterDays = (days: number): string => {
    const today = new Date();
    today.setDate(today.getDate() + days);

    // 날짜를 YYYY-MM-DD 형식으로 포맷팅
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const handleUploadClick = (name: "logo" | "file" | "image") => () => {
    switch (name) {
      case "logo":
        logoEl.current.click();
        return;
      case "file":
        fileUploadEl.current.click();
        return;
      case "image":
        posterImageUploadEl.current.click();
        return;
      default:
        return;
    }
  };

  //   const [name, searchedAddress, point, handlePopupAddress] = useSearchAddress();

  //   useEffect(() => {
  //     if (searchedAddress) {
  //       const { address: fullAddress, sido, sigungu } = searchedAddress;
  //       const region = regions[sido as Region]["region"].find((e) =>
  //         sigungu.includes(e.name)
  //       ).search;

  //       onSelect(
  //         name as keyof RegisterForm,
  //         {
  //           fullAddress,
  //           region,
  //           point,
  //         },
  //         "Address"
  //       )();
  //     }
  //   }, [searchedAddress, point]);

  useEffect(() => {
    if (errors.introduction && introductionField.current)
      return window.scrollTo(0, introductionField.current.offsetTop - 100);
    if (
      (errors.namePersonInCharge ||
        errors.emailPersonInCharge ||
        errors.phonePersonInCharge) &&
      personInChargeField.current
    )
      return window.scrollTo(0, personInChargeField.current.offsetTop - 100);
    if (
      (errors.applyMethod ||
        errors.applyMethodEmail ||
        errors.applyMethodHomepage ||
        errors.applyMethodPost) &&
      applyMethodField.current
    ) {
      return window.scrollTo(0, applyMethodField.current.offsetTop - 100);
    }
    if (errors.applyDate && applyDateField.current) {
      return window.scrollTo(0, applyDateField.current.offsetTop - 100);
    }
    if (errors.hireNotice && hireNoticeField.current) {
      return window.scrollTo(0, hireNoticeField.current.offsetTop - 100);
    }
    if (errors.title && titleField.current) {
      return window.scrollTo(0, titleField.current.offsetTop - 100);
    }
    if (errors.workType && workTypeField.current) {
      return window.scrollTo(0, workTypeField.current.offsetTop - 100);
    }
    if (errors.salary && salaryField.current) {
      return window.scrollTo(0, salaryField.current.offsetTop - 100);
    }
    if (errors.address && addressField.current) {
      return window.scrollTo(0, addressField.current.offsetTop - 100);
    }
    if (errors.message && messageField.current) {
      return window.scrollTo(0, messageField.current.offsetTop - 100);
    }
    if (errors.qualification && qualificationField.current) {
      return window.scrollTo(0, qualificationField.current.offsetTop - 100);
    }
  }, [errors]);

  if (!offer) {
    console.log("offer is null");
    return <div />;
  }

  if (showAddressPopup) {
    return (
      <SearchAddress
        onSelected={(
          address: string,
          region: string,
          name: string,
          phone: string,
          position: any,
          postalCode: string
        ): void => {}}
        onCanceled={(): void => {}}
        searchType="manual"
      />
    );
  }

  return (
    <RegisterFormLayout>
      <Text
        className="register__description"
        element="h4"
        textType="LargeTitle2"
        color={COLOR_SYSTEM.get("Gray")[800]}
      >
        회사를 소개해주세요
      </Text>
      <div className="company-info">
        <div className="info__logo">
          {offer.logoImageURL && (
            <img
              src={offer.logoImageURL}
              alt="회사 로고"
              style={{ objectFit: "contain" }}
            />
          )}
        </div>
        <div className="info__description">
          <div className="description__item">
            <Text
              textType="InputMedium"
              color={COLOR_SYSTEM.get("Gray")[600]}
              className="description__title"
            >
              회사(병원) 이름
            </Text>
            <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[800]}>
              {offer.companyName || ""}
            </Text>
          </div>
          <div className="description__item">
            <Text
              textType="InputMedium"
              color={COLOR_SYSTEM.get("Gray")[600]}
              className="description__title"
            >
              기업 형태
            </Text>
            {offer.companyType && (
              <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[800]}>
                {CompanyTypeName[offer.companyType]}
              </Text>
            )}
          </div>
          <div className="description__item">
            <Text
              textType="InputMedium"
              color={COLOR_SYSTEM.get("Gray")[600]}
              className="description__title"
            >
              직원 수
            </Text>
            {offer.employeeCnt && (
              <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[800]}>
                {employeeCntParser[offer.employeeCnt]}
              </Text>
            )}
          </div>
          {offer.homepage && (
            <div className="description__item">
              <Text
                textType="InputMedium"
                color={COLOR_SYSTEM.get("Gray")[600]}
                className="description__title"
              >
                홈페이지 주소
              </Text>
              <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[800]}>
                {offer.homepage}
              </Text>
            </div>
          )}
          <div className="description__item">
            <Text
              textType="InputMedium"
              color={COLOR_SYSTEM.get("Gray")[600]}
              className="description__title"
            >
              대표 주소
            </Text>
            <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[800]}>
              {offer.companyAddress} {offer.companyAddressDetail}
            </Text>
          </div>
        </div>
      </div>
      <div className="register__introduce" ref={introductionField}>
        <WysiwygEditor
          title="회사 소개"
          required
          name="introduction"
          value={offer.introduction}
          placeholder={
            "지원자가 회사에 매력을 느낄 수 있는 소개 문구를 입력해주세요.\n회사 홈페이지의 문구를 옮겨 적으셔도 됩니다."
          }
          onChange={(name: any, value: string) => {
            onChange({ introduction: value });
          }}
          error={errors.introduction}
        />
        {errors.introduction && (
          <Text
            textType="InputSmall"
            color={COLOR_SYSTEM.get("Red")[400]}
            className="description__title"
          >
            회사 소개를 입력해주세요
          </Text>
        )}
      </div>
      <Text
        className="register__description"
        element="h4"
        textType="LargeTitle2"
        color={COLOR_SYSTEM.get("Gray")[800]}
      >
        지원자가 참고할 수 있도록
        <br />
        채용 정보를 알려주세요
      </Text>
      <InputField>
        <div className="register__employer-info" ref={personInChargeField}>
          <div>
            <Input
              label="채용 담당자"
              required
              placeholder="담당자 성함을 입력해주세요"
              name="namePersonInCharge"
              value={offer.namePersonInCharge}
              onChange={(e) => {
                onChange({ namePersonInCharge: e.target.value });
              }}
              error={errors.namePersonInCharge}
              hint={
                errors.namePersonInCharge ? "담당자 성함을 입력해주세요" : ""
              }
            />
          </div>
          <Input
            label="채용 담당 부서"
            placeholder="담당 부서를 입력해주세요"
            name="officeInCharge"
            value={offer.officeInCharge}
            onChange={(e) => {
              onChange({ officeInCharge: e.target.value });
            }}
            onClear={() => {
              onChange({ officeInCharge: "" });
            }}
          />
          <div>
            <Input
              label="이메일 주소"
              required
              placeholder="연락 받으실 이메일 주소를 입력해주세요"
              name="emailPersonInCharge"
              value={offer.emailPersonInCharge}
              onChange={(e) => {
                onChange({ emailPersonInCharge: e.target.value });
              }}
              onClear={() => {
                onChange({ emailPersonInCharge: "" });
              }}
              error={errors.emailPersonInCharge}
              hint={
                errors.emailPersonInCharge ? "이메일을 잘못 입력했어요" : ""
              }
            />
          </div>
          <div>
            <Input
              label="전화번호"
              placeholder="연락받으실 전화번호를 입력해주세요"
              name="phonePersonInCharge"
              value={offer.phonePersonInCharge}
              onChange={(e) => {
                onChange({ phonePersonInCharge: e.target.value });
              }}
              onClear={() => {
                onChange({ phonePersonInCharge: "" });
              }}
              error={errors.phonePersonInCharge}
              hint={
                errors.phonePersonInCharge ? "전화번호를 잘못 입력했어요" : ""
              }
            />
          </div>
        </div>
        <div className="register__path" ref={applyMethodField}>
          <div className="field__title">
            <Text
              textType="InputMedium"
              color={
                errors.applyMethod
                  ? COLOR_SYSTEM.get("Red")[400]
                  : COLOR_SYSTEM.get("Gray")[600]
              }
            >
              지원 방법
            </Text>
            <Text textType="InputSmall" color={COLOR_SYSTEM.get("Red")[300]}>
              *
            </Text>
          </div>
          <div className="field__checkbox-list">
            <div className="checkbox">
              <CheckBox
                size="Small"
                checked={offer.applyMethod.includes(ApplyMethod.Email)}
                onClick={() => {
                  if (offer.applyMethod.includes(ApplyMethod.Email)) {
                    onChange({
                      applyMethod: offer.applyMethod.replace(
                        ApplyMethod.Email,
                        ""
                      ) as ApplyMethod,
                    });
                  } else {
                    onChange({
                      applyMethod: offer.applyMethod + ApplyMethod.Email,
                    });
                  }
                }}
              >
                이메일 지원
              </CheckBox>
              <div className="checkbox__input">
                {offer.applyMethod.includes(ApplyMethod.Email) && (
                  <Input
                    placeholder={offer.emailPersonInCharge || ""}
                    value={offer.applyMethodDetailEmail}
                    name="applyMethodDetailEmail"
                    onChange={(e) => {
                      onChange({ applyMethodDetailEmail: e.target.value });
                    }}
                    onClear={() => {
                      onChange({ applyMethodDetailEmail: "" });
                    }}
                    error={errors.applyMethodEmail}
                    hint={
                      errors.applyMethodEmail ? "이메일을 잘못 입력했어요" : ""
                    }
                  />
                )}
              </div>
            </div>
            <div className="checkbox">
              <CheckBox
                size="Small"
                checked={offer.applyMethod.includes(ApplyMethod.Homepage)}
                onClick={() => {
                  if (offer.applyMethod.includes(ApplyMethod.Homepage)) {
                    onChange({
                      applyMethod: offer.applyMethod.replace(
                        ApplyMethod.Homepage,
                        ""
                      ) as ApplyMethod,
                    });
                  } else {
                    onChange({
                      applyMethod: offer.applyMethod + ApplyMethod.Homepage,
                    });
                  }
                }}
              >
                홈페이지 지원
              </CheckBox>
              <div className="checkbox__input">
                {offer.applyMethod.includes(ApplyMethod.Homepage) && (
                  <Input
                    placeholder={"https://"}
                    value={offer.applyMethodDetailHomepage}
                    name="applyMethodDetailHomepage"
                    onChange={(e) => {
                      onChange({ applyMethodDetailHomepage: e.target.value });
                    }}
                    onClear={() => {
                      onChange({ applyMethodDetailHomepage: "" });
                    }}
                    error={errors.applyMethodHomepage}
                    hint={
                      errors.applyMethodHomepage
                        ? "홈페이지를 잘못 입력했어요"
                        : ""
                    }
                  />
                )}
              </div>
            </div>
            <div className="checkbox multi-input">
              <CheckBox
                size="Small"
                checked={offer.applyMethod.includes(ApplyMethod.Post)}
                onClick={() => {
                  if (offer.applyMethod.includes(ApplyMethod.Post)) {
                    onChange({
                      applyMethod: offer.applyMethod.replace(
                        ApplyMethod.Post,
                        ""
                      ) as ApplyMethod,
                    });
                  } else {
                    onChange({
                      applyMethod: offer.applyMethod + ApplyMethod.Post,
                    });
                  }
                }}
              >
                우편 지원
              </CheckBox>
              {offer.applyMethod.includes(ApplyMethod.Post) && (
                <div
                  className="checkbox__input"
                  style={{ position: "relative" }}
                >
                  <Input
                    placeholder={offer.companyAddress || ""}
                    value={offer.applyMethodDetailPost}
                    name="applyMethodDetailPost"
                    error={errors.applyMethodPost}
                    onClick={() => {
                      setShowAddressPopup(2);
                    }}
                    readonly
                  />
                  <Input
                    placeholder={offer.companyAddressDetail || ""}
                    value={offer.applyMethodDetailPostDetail}
                    name="applyMethodDetailPostDetail"
                    onChange={(e) => {
                      onChange({ applyMethodDetailPostDetail: e.target.value });
                    }}
                    onClear={() => {
                      onChange({ applyMethodDetailPostDetail: "" });
                    }}
                    error={errors.applyMethodPost}
                    hint={errors.applyMethodPost ? "주소를 입력해주세요" : ""}
                  />
                </div>
              )}
            </div>
            {errors.applyMethod && (
              <Text
                textType="InputSmall"
                color={COLOR_SYSTEM.get("Red")[400]}
                className="description__title"
              >
                지원 방법을 선택해주세요
              </Text>
            )}
          </div>
        </div>
        <div className="register__document">
          <WysiwygEditor
            title="제출 서류"
            subTitle="지원 시 제출해야할 서류를 작성해주세요."
            name="submissionDoc"
            value={offer.submissionDoc}
            onChange={(name: any, value: string) => {
              onChange({ submissionDoc: value });
            }}
          />
        </div>
        {/* <div className="register__file">
          <div>
            <div className="field__title field-description">
              <Text
                textType="InputMedium"
                color={COLOR_SYSTEM.get("Gray")[600]}
              >
                양식 파일
              </Text>
              <Text
                textType="InputSmall"
                color={COLOR_SYSTEM.get("Gray")[300]}
              >
                이력서 양식 등 지원자가 준수해야할 양식 파일이 있으면 첨부해
                주세요.
              </Text>
            </div>
            <Button
              color="Secondary"
              size="Medium"
              variant="Outlined"
              type="IconWithText"
              icon="Clip"
              left
              onClick={handleUploadClick("file")}
            >
              첨부하기
            </Button>
            <input
              type="file"
              accept=".doc, .docx, .ppt, .pptx, .pdf, .hwp"
              name="submissionDocFormFile"
              style={{ display: "none" }}
              ref={fileUploadEl}
              onChange={onUpload}
              multiple
            />
          </div>
          {offer.submissionDocForms && (
            <UploadedFiles>
              {offer.submissionDocForms.map((file: File) => (
                <File key={file.name}>
                  <div className="file-icon">
                    <Icon
                      size={22}
                      color={COLOR_SYSTEM.get("Skyblue")[500]}
                      icon="Document"
                    />
                  </div>
                  <div className="file-info">
                    <Text
                      textType="Body2Medium"
                      color={COLOR_SYSTEM.get("Gray")[900]}
                    >
                      {file.name}
                    </Text>
                    <br />
                    <Text
                      textType="CaptionBold"
                      color={COLOR_SYSTEM.get("Gray")[400]}
                    >
                      {getByteSize(file.size)}
                    </Text>
                  </div>
                  <Button
                    color="Tertiary"
                    variant="Ghost"
                    size="Small"
                    type="Icon"
                    icon="X"
                    onClick={onRemoveFile("submissionDocFormFile", file)}
                  />
                </File>
              ))}
            </UploadedFiles>
          )}
        </div> */}
        <div className="register__date">
          <div className="field__title">
            <Text
              textType="InputMedium"
              color={
                errors.applyDate
                  ? COLOR_SYSTEM.get("Red")[400]
                  : COLOR_SYSTEM.get("Gray")[600]
              }
            >
              채용 기간
            </Text>
            <Text textType="InputSmall" color={COLOR_SYSTEM.get("Red")[300]}>
              *
            </Text>
          </div>
          <div className="field__range">
            <Input
              placeholder={offer.companyAddress || ""}
              value={offer.applyMethodDetailPost}
              name="applyMethodDetailPost"
              error={errors.applyMethodPost}
              onClick={() => {
                setShowAddressPopup(2);
              }}
              readonly
            />
            ~{" "}
            <Input
              placeholder={offer.companyAddress || ""}
              value={offer.applyMethodDetailPost}
              name="applyMethodDetailPost"
              error={errors.applyMethodPost}
              onClick={() => {
                setShowAddressPopup(2);
              }}
              readonly
            />
            {/* <div className="apply-date__checkbox">
              <CheckBox
                size="Small"
                checked={isAlwaysRecruit}
                onClick={onToggleCheckBox("alwaysRecruit")}
              >
                채용시까지
              </CheckBox>
            </div> */}
          </div>
          {errors.applyDate && (
            <Text
              textType="InputSmall"
              color={COLOR_SYSTEM.get("Red")[400]}
              className="description__title"
            >
              채용 기간을 입력해주세요
            </Text>
          )}
          <Text textType="Caption" color={COLOR_SYSTEM.get("Blue")[200]}>
            👆️ 공고의 최대노출 기한은 60일이며, {getDateAfterDays(60)} 이후
            자동으로 종료됩니다.
          </Text>
        </div>
        <div className="register__process">
          <WysiwygEditor
            title="채용 절차"
            placeholder={
              "지원자가 알아야할 채용절차에 대해서 간략히 작성해주세요\n예시) 서류전형 → 면접전형(대면) → 최종합격"
            }
            name="hireProcess"
            value={offer.hireProcess}
            onChange={(name: any, value: string) => {
              onChange({ hireProcess: value });
            }}
          />
        </div>
        <div className="register__result" ref={hireNoticeField}>
          <WysiwygEditor
            title="채용 결과 안내"
            required
            placeholder={
              "채용결과 안내 방식을 작성해주세요\n예시1) 개인별로 합격여부를 문자 메세지로 안내합니다.\n예시2) 회사 홈페이지에 합격자를 발표합니다."
            }
            name="hireNotice"
            value={offer.hireNotice}
            onChange={(name: any, value: string) => {
              onChange({ hireNotice: value });
            }}
            error={errors.hireNotice}
          />
          {errors.hireNotice && (
            <Text
              textType="InputSmall"
              color={COLOR_SYSTEM.get("Red")[400]}
              className="description__title"
            >
              채용 결과 안내를 입력해주세요
            </Text>
          )}
        </div>
      </InputField>
      <Text
        className="register__description"
        element="h4"
        textType="LargeTitle2"
        color={COLOR_SYSTEM.get("Gray")[800]}
      >
        채용하실 약사님의
        <br /> 직무 정보를 알려주세요
      </Text>
      <InputField>
        <div className="register__job" ref={titleField}>
          <Input
            label="직무"
            required
            placeholder="예) 약제팀 주간 근무 약사"
            value={offer.title}
            name="title"
            onChange={(e) => {
              onChange({ title: e.target.value });
            }}
            onClear={() => {
              onChange({ title: "" });
            }}
            error={errors.title}
            hint={errors.title ? "직무를 입력해주세요" : ""}
          />
        </div>
        <div className="register__employment-type" ref={workTypeField}>
          <ToggleButton
            active={offer.workType === JobOfferType.EMPLOYED}
            onClick={() => {
              onChange({ workType: JobOfferType.EMPLOYED });
            }}
            color={"Gray"}
            size={"Large"}
            variant={"Outlined"}
          >
            정규직
          </ToggleButton>
          <ToggleButton
            active={offer.workType === JobOfferType.TEMPORARY}
            onClick={() => {
              onChange({ workType: JobOfferType.TEMPORARY });
            }}
            color={"Gray"}
            size={"Large"}
            variant={"Outlined"}
          >
            계약직
          </ToggleButton>

          {errors.workType && (
            <Text
              textType="InputSmall"
              color={COLOR_SYSTEM.get("Red")[400]}
              className="description__title"
            >
              고용 형태를 선택해주세요
            </Text>
          )}
        </div>
        <div className="register__probation-period">
          <Input
            className={
              offer.workType === JobOfferType.NONE ||
              offer.workType !== JobOfferType.EMPLOYED
                ? "disabled"
                : ""
            }
            label="수습 기간"
            value={
              offer.workType === JobOfferType.EMPLOYED
                ? offer.contractPeriod + ""
                : ""
            }
            name="contractPeriod"
            placeholder="수습 기간을 입력해주세요"
            onChange={(e) => {
              onChange({ contractPeriod: Number(e.target.value) });
            }}
            onClear={() => {
              onChange({ contractPeriod: 0 });
            }}
            readonly={offer.workType !== JobOfferType.EMPLOYED}
            rightItems={["개월"]}
          />
          <Input
            className={
              offer.workType === JobOfferType.NONE ||
              offer.workType !== JobOfferType.TEMPORARY
                ? "disabled"
                : ""
            }
            label="계약 기간"
            rightItems={["개월"]}
            name="contractPeriod"
            placeholder="계약 기간을 입력해주세요"
            onChange={(e) => {
              onChange({ contractPeriod: Number(e.target.value) });
            }}
            onClear={() => {
              onChange({ contractPeriod: 0 });
            }}
            readonly={offer.workType !== JobOfferType.TEMPORARY}
          />
        </div>
        <div className="register__salary" ref={salaryField}>
          <div className="field__title">
            <Text
              textType="InputMedium"
              color={
                errors.salary || errors.salaryLow || errors.salaryHigh
                  ? COLOR_SYSTEM.get("Red")[400]
                  : COLOR_SYSTEM.get("Gray")[600]
              }
            >
              급여
            </Text>
            <Text textType="InputSmall" color={COLOR_SYSTEM.get("Red")[300]}>
              *
            </Text>
          </div>
          <div className="field__range">
            <div className="salary-input">
              {!offer.canNegoSalary && (
                <div
                  className={
                    offer.canNegoSalary
                      ? "range__input-field disabled"
                      : "range__input-field"
                  }
                >
                  <div className="dropdown">
                    <ToggleButton
                      active={offer.salaryType === JobOfferSalaryType.ANUAL}
                      onClick={() => {
                        onChange({ salaryType: JobOfferSalaryType.ANUAL });
                      }}
                      color={"Gray"}
                      size={"Medium"}
                      variant={"Tinted"}
                    >
                      연봉
                    </ToggleButton>
                    <ToggleButton
                      active={offer.salaryType === JobOfferSalaryType.MONTHLY}
                      onClick={() => {
                        onChange({ salaryType: JobOfferSalaryType.MONTHLY });
                      }}
                      color={"Gray"}
                      size={"Medium"}
                      variant={"Tinted"}
                    >
                      월급
                    </ToggleButton>
                    <ToggleButton
                      active={offer.salaryType === JobOfferSalaryType.DAILY}
                      onClick={() => {
                        onChange({ salaryType: JobOfferSalaryType.DAILY });
                      }}
                      color={"Gray"}
                      size={"Medium"}
                      variant={"Tinted"}
                    >
                      일급
                    </ToggleButton>
                    <ToggleButton
                      active={offer.salaryType === JobOfferSalaryType.HOURLY}
                      onClick={() => {
                        onChange({ salaryType: JobOfferSalaryType.HOURLY });
                      }}
                      color={"Gray"}
                      size={"Medium"}
                      variant={"Tinted"}
                    >
                      시급
                    </ToggleButton>
                  </div>

                  <Input
                    required
                    placeholder="최소 급여"
                    value={offer.salaryRangeLow + ""}
                    name="salaryRangeLow"
                    rightItems={["원"]}
                    hint={
                      errors.salaryLow
                        ? `${offer.salaryRangeHigh}원 이하로 입력해주세요`
                        : `최소 ${offer.salaryRangeLow}원`
                    }
                    onChange={(e) => {
                      onChange({ salaryRangeLow: Number(e.target.value) });
                    }}
                    onClear={() => {
                      onChange({ salaryRangeLow: 0 });
                    }}
                    error={errors.salary || errors.salaryLow}
                  />
                  <Text
                    textType="InputLarge"
                    color={COLOR_SYSTEM.get("Gray")[800]}
                    className="salary-range__tilde"
                  >
                    ~
                  </Text>
                  <Input
                    required
                    placeholder="최대 급여"
                    value={offer.salaryRangeHigh + ""}
                    name="salaryRangeHigh"
                    rightItems={["원"]}
                    hint={
                      errors.salaryHigh
                        ? `${offer.salaryRangeLow}원 이상으로 입력해주세요`
                        : `최대 ${offer.salaryRangeHigh}원`
                    }
                    onChange={(e) => {
                      onChange({ salaryRangeHigh: Number(e.target.value) });
                    }}
                    onClear={() => {
                      onChange({ salaryRangeHigh: 0 });
                    }}
                    readonly={offer.canNegoSalary}
                    error={errors.salary || errors.salaryHigh}
                  />
                </div>
              )}
            </div>
            <CheckBox
              size="Small"
              checked={offer.canNegoSalary}
              onClick={(e) => {
                onChange({ canNegoSalary: !offer.canNegoSalary });
              }}
            >
              추후 협의
            </CheckBox>
          </div>
          {errors.salary && (
            <Text
              textType="InputSmall"
              color={COLOR_SYSTEM.get("Red")[400]}
              style={{ display: "block", marginTop: "20px" }}
            >
              급여를 입력해주세요
            </Text>
          )}
        </div>
        <div className="register__workplace" ref={addressField}>
          <div className="field__title">
            <Text
              textType="InputMedium"
              color={
                errors.address
                  ? COLOR_SYSTEM.get("Red")[400]
                  : COLOR_SYSTEM.get("Gray")[600]
              }
            >
              근무지
            </Text>
            <Text textType="InputSmall" color={COLOR_SYSTEM.get("Red")[300]}>
              *
            </Text>
          </div>
          <div className="register__address">
            <div style={{ position: "relative" }}>
              <Input
                placeholder="지번/도로명/건물명으로 검색해주세요"
                value={offer.companyAddress}
                name="companyAddress"
                error={errors.address}
                onClick={(e) => setShowAddressPopup(1)}
                readonly
              />
            </div>
            <Input
              placeholder="상세주소를 입력해주세요"
              value={offer.companyAddressDetail}
              name="companyAddressDetail"
              onChange={(e) => {
                onChange({ companyAddressDetail: e.target.value });
              }}
              onClear={() => {
                onChange({ companyAddressDetail: "" });
              }}
              error={errors.address}
              hint={errors.address ? "근무지 주소를 입력해주세요" : ""}
            />
          </div>
          {offer.companyPosition.x || offer.companyPosition.y ? (
            <div style={{ marginTop: "8px" }}>
              <Text textType="InputSmall" color={COLOR_SYSTEM.get("Gray")[400]}>
                정확한 위치를 클릭해서 📍 을 옮겨주세요
              </Text>
              <div
                id="map"
                style={{
                  width: "100%",
                  height: "400px",
                  borderRadius: "20px",
                  marginTop: "8px",
                }}
              ></div>
            </div>
          ) : null}
        </div>
        <div className="register__main-work" ref={messageField}>
          <WysiwygEditor
            title="주요 업무"
            required
            subTitle="담당 직무에서 수행하는 내용을 작성해주세요."
            name="message"
            value={offer.message}
            onChange={(name: any, value: string) => {
              onChange({ message: value });
            }}
            error={errors.message}
          />
          {errors.message && (
            <Text
              textType="InputSmall"
              color={COLOR_SYSTEM.get("Red")[400]}
              className="description__title"
            >
              주요 업무를 입력해주세요
            </Text>
          )}
        </div>
        <div className="register__qualifications" ref={qualificationField}>
          <WysiwygEditor
            title="자격 요건"
            required
            subTitle="지원자가 필수적으로 보유해야할 자격에 대해 작성해주세요."
            name="qualification"
            value={offer.qualification}
            onChange={(name: any, value: string) => {
              onChange({ qualification: value });
            }}
            error={errors.qualification}
          />
          {errors.qualification && (
            <Text
              textType="InputSmall"
              color={COLOR_SYSTEM.get("Red")[400]}
              className="description__title"
            >
              자격 요건을 입력해주세요
            </Text>
          )}
        </div>
        <div className="register__prefer">
          <WysiwygEditor
            title="우대 사항"
            subTitle="채용 시 가점 요소가 되는 자격 요건, 직무 경험 등을 작성해주세요"
            name="preference"
            value={offer.preference}
            onChange={(name: any, value: string) => {
              onChange({ preference: value });
            }}
          />
        </div>
        <div>
          <WysiwygEditor
            title="근무 조건"
            subTitle="근무 요일, 시간 등 근무 조건을 작성해주세요"
            name="workTime"
            value={offer.workTime}
            onChange={(name: any, value: string) => {
              onChange({ workTime: value });
            }}
          />
        </div>
        <div className="register__benefits">
          <WysiwygEditor
            title="혜택 및 복지"
            subTitle="채용된 직원에게 제공되는 혜택과 복지에 대해 작성해주세요"
            name="welfare"
            value={offer.welfare}
            onChange={(name: any, value: string) => {
              onChange({ welfare: value });
            }}
          />
        </div>
        <div className="register__poster">
          <div className="field__title field-description">
            <Text textType="InputMedium" color={COLOR_SYSTEM.get("Gray")[600]}>
              채용 홍보 이미지
            </Text>
            <Text textType="InputSmall" color={COLOR_SYSTEM.get("Gray")[300]}>
              기타 첨부하실 홍보 이미지가 있다면 첨부해주세요
            </Text>
          </div>
          <Button
            color="Secondary"
            size="Medium"
            variant="Outlined"
            type="IconWithText"
            icon="Clip"
            left
            onClick={handleUploadClick("image")}
          >
            첨부하기
          </Button>
          {/* <input
            type="file"
            accept="image/png, image/jpeg"
            name="imageFiles"
            style={{ display: "none" }}
            ref={posterImageUploadEl}
            onChange={onUpload}
            multiple
          />

          {offer.imageFiles && (
            <UploadedFiles>
              {offer.imageFiles.map((file) => (
                <File key={file.name}>
                  <div className="file-icon">
                    <Icon
                      size={22}
                      color={COLOR_SYSTEM.get("Skyblue")[500]}
                      icon="Document"
                    />
                  </div>
                  <div className="file-info">
                    <Text
                      textType="Body2Medium"
                      color={COLOR_SYSTEM.get("Gray")[900]}
                    >
                      {file.name}
                    </Text>
                    <br />
                    <Text
                      textType="CaptionBold"
                      color={COLOR_SYSTEM.get("Gray")[400]}
                    >
                      {getByteSize(file.size)}
                    </Text>
                  </div>
                  <Button
                    color="Tertiary"
                    variant="Ghost"
                    size="Small"
                    type="Icon"
                    icon="X"
                    onClick={onRemoveFile("imageFiles", file)}
                  />
                </File>
              ))}
            </UploadedFiles>
          )} */}
        </div>
      </InputField>
    </RegisterFormLayout>
  );
};

export default JobPostBizRegistration;
