import React, { Component } from "react";
import "./../../UserManage.css";
import "./../../../Common.css";
import "./../../Admin.scss";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonButton,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonList,
  IonItem,
  IonTextarea,
  IonInput,
  IonCheckbox,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonLabel,
} from "@ionic/react";
import { fetchAPI } from "./../../../utils/APIUtil";
import { timeout, async } from "q";
import smileIcon from "./../assets/icon/smile.svg";
import { log, LogLevel } from "../../../utils/LogUtil";
import {
  UserInfo,
  UserWorkTypeName,
  UserLevel,
  UserLevelName,
  RegisterRoute,
  REGISTER_ROUTE_NAME,
  UserPermanentType,
  UserWorkType,
  UserState,
} from "../../../models/Model.User";
import { getGlobal, GlobalKey } from "../../../utils/GlobalUtil";
import { triggerAsyncId } from "async_hooks";
import { actions, RootState } from "../../../store";
import { connect } from "react-redux";
import { UIPopupType } from "../../../store/ui/types";
import { BoardAttribute } from "../../../models/Model.Board";
import {
  getDateStringFromToday,
  getDateTimeString,
} from "../../../utils/TimeUtil";
import StringUtil from "../../../utils/StringUtil";
import IconButton from "../../../component/atom/IconButton";
import { callPhone, sendSMSMessage } from "../../../utils/UrlSchemeUtil";
import { JobOfferName } from "../../../models/Model.JobPost";
import UserUtil from "../../../utils/UserUtil";
import Text from "../../../components/atoms/Text";
import { COLOR_SYSTEM } from "../../../components/design/design-system";

const API = {
  USER_UPDATE: {
    method: "PUT",
    path: "/admin/user/user",
    contentType: "application/json",
  },
  USER_DELETE: {
    method: "DELETE",
    path: "/admin/user/user",
    contentType: "application/json",
  },
  USER_GET: {
    method: "GET",
    path: "/admin/user/user",
    contentType: "application/json",
  },
  USER_SNS_MERGE: {
    method: "PUT",
    path: "/admin/user/sns/merge",
    contentType: "application/json",
  },
  USER_GET_SNS: {
    method: "GET",
    path: "/admin/user/sns/",
    contentType: "application/json",
  },
  USER_SNS_ABLE: {
    method: "PUT",
    path: "/admin/user/sns/able",
    contentType: "application/json",
  },
  USER_CLEAR_WORKTIME_EDITED_AT: {
    method: "DELETE",
    path: "/admin/user/deleteWorkTypeEditedAt/",
    contentType: "application/json",
  },
};

type Props = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    onDone: () => void;
    onCancel: () => void;
    user: UserInfo;
  };

type State = {
  adminMessage: string;
  level: UserLevel;
  license: string;
  workType: UserWorkType;
  workTypeChange: boolean;
  state: UserState;
  memo: string;
  name: string;
  nameChange: boolean;
  nickname: string;
  nicknameChange: boolean;
  phone: string;
  phoneChange: boolean;
  email: string;
  emailChange: boolean;
  birthGender: string;
  edited: boolean;
  birthday: string;
  gender: string;
  licensedAt: string;
  socialIds: any[];
  mergeIntoId: number;
  mergeIntoUser: UserInfo;
};

class UserManageProfile extends Component<Props, State> {
  state = {
    adminMessage: "",
    level: UserLevel.ALL,
    license: "",
    workType: UserWorkType.PHARMACY_OWNER,
    workTypeChange: false,
    state: UserState.NONE,
    memo: "",
    name: "",
    nameChange: false,
    nickname: "",
    nicknameChange: false,
    phone: "",
    phoneChange: false,
    email: "",
    emailChange: false,
    birthGender: "",
    birthday: "",
    gender: "",
    licensedAt: "",
    edited: false,

    socialIds: null,
    mergeIntoId: 0,
    mergeIntoUser: null,
  };

  constructor(props: Props) {
    super(props);
    if (props.user) {
      log(LogLevel.UI_DATA_LOAD, "UserManageProfile", this.props.user);
      this.state.adminMessage = "";
      this.state.level = props.user.level;
      this.state.name = props.user.name;
      this.state.phone = props.user.phone;
      this.state.email = props.user.email;
      this.state.birthday = props.user.birthday;
      this.state.gender = props.user.gender;
      this.state.licensedAt =
        props.user.workType == UserWorkType.UNDERGRADUATE
          ? "0000*"
          : !props.user.licensedAt || props.user.licensedAt == "0000*"
          ? UserUtil.guessUserLicensedAt(props.user.license) + "*"
          : props.user.licensedAt;
      this.state.state = props.user.state;
      this.state.memo = props.user.memo;
    }
  }

  componentDidUpdate(prevProps, nextState) {
    if (
      this.props.user &&
      (!prevProps.user || this.props.user.id != prevProps.user.id)
    ) {
      this.setState({
        adminMessage: "",
        level: this.props.user.level,
        license: this.props.user.license,
        workType: this.props.user.workType,
        name: this.props.user.name,
        nickname: this.props.user.nickname,
        birthGender: this.props.user.birthGender,
        phone: this.props.user.phone,
        email: this.props.user.email,
        birthday: this.props.user.birthday,
        gender: this.props.user.gender,
        licensedAt:
          this.props.user.workType == UserWorkType.UNDERGRADUATE &&
          this.props.user.state != UserState.LICENSE_CHECK
            ? "0000*"
            : !this.props.user.licensedAt ||
              this.props.user.licensedAt == "0000*"
            ? UserUtil.guessUserLicensedAt(this.props.user.license) + "*"
            : this.props.user.licensedAt,
        edited: false,
        workTypeChange: false,
        nameChange: false,
        nicknameChange: false,
        phoneChange: false,
        emailChange: false,
        socialIds: null,
        mergeIntoId: 0,
        mergeIntoUser: null,
        state: this.props.user.state,
        memo: this.props.user.memo,
      });
      log(
        LogLevel.UI_DATA_LOAD,
        "UserManageProfile:componentWillUpdate",
        prevProps.user,
        this.state
      );
    }
  }

  onDone = async () => {
    if (!this.isEditable()) {
      log(LogLevel.UI_ACTION, "UserManageProfile.onDone not editable");
      return;
    }

    let updateUser: UserInfo = {
      id: this.props.user.id,
      level: this.state.level,
      managerMessage: this.state.adminMessage,
      license: this.state.license,
      birthGender: this.state.birthGender,
      birthday: this.state.birthday,
      gender: this.state.gender,
      licensedAt: this.state.licensedAt,
      state: this.state.state,
      memo: this.state.memo,
    };

    if (this.state.nameChange) {
      updateUser.name = this.state.name;
    }
    if (this.state.nicknameChange) {
      updateUser.nickname = this.state.nickname;
    }
    if (this.state.phoneChange) {
      updateUser.phone = this.state.phone;
    }
    if (this.state.emailChange) {
      updateUser.email = this.state.email;
    }
    if (this.state.workTypeChange) {
      updateUser.workType = this.state.workType;
    }

    log(LogLevel.UI_ACTION, "UserManageProfile Update", updateUser);
    let result = await fetchAPI(
      API.USER_UPDATE,
      "",
      null,
      updateUser,
      getGlobal(GlobalKey.TOKEN)
    );
    if (result && !result.error) {
      this.props.onDone();
    } else {
      log(LogLevel.UI_EXCEPTION, "UserManageProfile Update failed", result);
    }
  };

  onReject = async () => {
    if (!this.isRejectable()) {
      if (!this.isEditable()) {
        log(LogLevel.UI_ACTION, "UserManageProfile.onDone not rejectable");
        return;
      }
    }

    let updateUser: UserInfo = {
      id: this.props.user.id,
      level: UserLevel.DENIED_USER,
      managerMessage: this.state.adminMessage,
      memo:
        (this.state.memo ? "" : "\n") +
        `[${getDateStringFromToday()}] 반려 (반려자:${this.props.me.name})\n${
          this.state.adminMessage
        }\n`,
    };

    log(LogLevel.UI_ACTION, "UserManageProfile onReject", updateUser);
    let result = await fetchAPI(
      API.USER_UPDATE,
      "",
      null,
      updateUser,
      getGlobal(GlobalKey.TOKEN)
    );
    if (result && !result.error) {
      this.props.onDone();
    } else {
      log(LogLevel.UI_EXCEPTION, "UserManageProfile Update failed", result);
    }
  };

  onAccept = async () => {
    let updateUser: UserInfo = {
      id: this.props.user.id,
      level: UserLevel.NORMAL_USER,
      license: this.state.license,
      licenseUrl: "",
      birthGender: this.state.birthGender,
      birthday: this.state.birthday,
      gender: this.state.gender,
      licensedAt: this.state.licensedAt,
      memo:
        (this.state.memo ? "" : "\n") +
        `[${getDateStringFromToday()}] 승인 (승인자:${this.props.me.name})`,
    };

    if (this.state.nameChange) {
      updateUser.name = this.state.name;
    }
    if (this.state.nicknameChange) {
      updateUser.nickname = this.state.nickname;
    }
    log(LogLevel.UI_ACTION, "UserManageProfile onAccept", updateUser);
    let result = await fetchAPI(
      API.USER_UPDATE,
      "",
      null,
      updateUser,
      getGlobal(GlobalKey.TOKEN)
    );
    if (result && !result.error) {
      this.props.onDone();
    } else {
      log(LogLevel.UI_EXCEPTION, "UserManageProfile Update failed", result);
    }
  };

  onMessageChange = (e: CustomEvent) => {
    // log(LogLevel.NONE, e);
    this.setState({ adminMessage: e.detail.value });
  };

  onLevelChange = async (e: CustomEvent) => {
    log(LogLevel.UI_EVENT, e);
    let level: UserLevel = parseInt(e.detail.value);
    this.setState({ level: level });
  };

  changeLevel = (level: UserLevel) => {
    this.setState({ level: level });
  };

  isEditable = () => {
    if (this.state.edited) return true;
    if (
      this.state.level != this.props.user.level &&
      this.state.level == UserLevel.DENIED_USER &&
      !this.state.adminMessage
    )
      return false;
    return true;
  };

  isRejectable = () => {
    if (!this.state.adminMessage) return false;
    return true;
  };

  onDeleteLicense = async () => {
    let updateUser: UserInfo = {
      id: this.props.user.id,
      licenseUrl: "",
    };

    log(LogLevel.UI_ACTION, "UserManageProfile onDeleteLicense", updateUser);
    let result = await fetchAPI(
      API.USER_UPDATE,
      "",
      null,
      updateUser,
      getGlobal(GlobalKey.TOKEN)
    );
    if (result && !result.error) {
      this.props.onDone();
    } else {
      log(LogLevel.UI_EXCEPTION, "UserManageProfile Update failed", result);
    }
  };

  onClearworkTypeEditedAt = () => {
    this.props.confirmPopup.show({
      iconImage: this.props.user.profileUrl,
      title: (
        <div className="common-container">
          <span className="common-bold">{this.props.user.name}</span> 회원님의
          근무유형 변경을{" "}
          <span className="common-bold common-color-caution">허락</span>{" "}
          시키시겠습니까?
        </div>
      ),
      body: <div className="common-container">원복이 불가능합니다.</div>,
      onDone: this.clearworkTypeEditedAt,
      doneText: "네",
    });
  };

  clearworkTypeEditedAt = async () => {
    let result = await fetchAPI(
      API.USER_CLEAR_WORKTIME_EDITED_AT,
      this.props.user.id,
      null,
      null,
      getGlobal(GlobalKey.TOKEN)
    );
    if (result && !result.error) {
      this.props.onDone();
    } else {
      log(LogLevel.UI_EXCEPTION, "clearworkTypeEditedAt Update failed", result);
    }
  };

  onDeleteProfile = () => {
    this.props.confirmPopup.show({
      iconImage: this.props.user.profileUrl,
      title: (
        <div className="common-container">
          <span className="common-bold">{this.props.user.name}</span> 회원님의
          프로필을 정말로{" "}
          <span className="common-bold common-color-caution">삭제</span>{" "}
          시키시겠습니까?
        </div>
      ),
      body: <div className="common-container">원복이 불가능합니다.</div>,
      onDone: this.doDeleteProfile,
      doneText: "네",
    });
  };

  doDeleteProfile = async () => {
    let updateUser: UserInfo = {
      id: this.props.user.id,
      profileUrl: "",
    };

    log(LogLevel.UI_ACTION, "UserManageProfile onDeleteProfile", updateUser);
    let result = await fetchAPI(
      API.USER_UPDATE,
      "",
      null,
      updateUser,
      getGlobal(GlobalKey.TOKEN)
    );
    if (result && !result.error) {
      this.props.onDone();
    } else {
      log(LogLevel.UI_EXCEPTION, "UserManageProfile Update failed", result);
    }
  };

  onLicenseCheck = () => {
    this.props.confirmPopup.show({
      title: (
        <div>
          <span className="common-bold">{this.props.user.name}</span> 회원님의
          약사정보가 확실한가요?
        </div>
      ),
      body: (
        <div className="common-container">
          근무유형이 "휴직"으로 변경되고 근무지정보가 삭제됩니다.(원복불가)
        </div>
      ),
      onDone: this.doLicenseCheck,
      doneText: "네",
    });
  };

  onLicenseDeny = () => {
    this.props.confirmPopup.show({
      title: (
        <div>
          <span className="common-bold">{this.props.user.name}</span> 회원님의
          약사정보가 잘못되었나요?
        </div>
      ),
      body: (
        <div className="common-container">
          반려 메모를 남기셨나요? 나중에 이력추적에 도움이 됩니다. 상태를
          변경하세요.
        </div>
      ),
      onDone: () => this.doLicenseDeny(false),
      doneText: "대학생 상태로 유지",
      onCancel: () => this.doLicenseDeny(true),
      cancelText: "반려회원으로 변경",
    });
  };

  doLicenseCheck = async () => {
    let updateUser: UserInfo = {
      id: this.props.user.id,
      licenseUrl: "",
      workType: UserWorkType.LEAVE_OF_ABSENCE,
      workPlaceName: "",
      workPlaceAddress: "",
      state: UserState.NONE,
      licensedAt: this.state.licensedAt,
      memo:
        (this.state.memo ? this.state.memo : "\n") +
        `[${getDateStringFromToday()}] 약사인증 (승인자:${this.props.me.name})`,
    };
    log(LogLevel.UI_ACTION, "UserManageProfile doLicenseCheck", updateUser);
    let result = await fetchAPI(
      API.USER_UPDATE,
      "",
      null,
      updateUser,
      getGlobal(GlobalKey.TOKEN)
    );
    if (result && !result.error) {
      this.props.onDone();
    } else {
      log(
        LogLevel.UI_EXCEPTION,
        "UserManageProfile doLicenseCheck failed",
        result
      );
    }
  };

  doLicenseDeny = async (levelDown: boolean) => {
    let updateUser: UserInfo = {
      id: this.props.user.id,
    };

    if (levelDown) {
      updateUser.level = UserLevel.DENIED_USER;
      updateUser.state = UserState.LICENSE_CHECK;
      updateUser.memo =
        this.state.memo +
        `\n[${getDateStringFromToday()}] 약사반려 반려회원으로 강등 (반려자:${
          this.props.me.name
        })`;
    } else {
      updateUser.state = UserState.NONE;
      updateUser.memo =
        this.state.memo +
        `\n[${getDateStringFromToday()}] 약사반려 대학생유지 (반려자:${
          this.props.me.name
        })`;
    }

    log(LogLevel.UI_ACTION, "UserManageProfile doLicenseDeny", updateUser);
    let result = await fetchAPI(
      API.USER_UPDATE,
      "",
      null,
      updateUser,
      getGlobal(GlobalKey.TOKEN)
    );
    if (result && !result.error) {
      this.props.onDone();
    } else {
      log(
        LogLevel.UI_EXCEPTION,
        "UserManageProfile doLicenseDeny failed",
        result
      );
    }
  };

  onExpire = () => {
    this.props.confirmPopup.show({
      title: (
        <div className="common-container">
          <span className="common-bold">{this.props.user.name}</span> 회원님을
          정말로 <span className="common-bold common-color-caution">탈퇴</span>{" "}
          시키시겠습니까?
        </div>
      ),
      body: <div className="common-container"></div>,
      onDone: this.doExpire,
      doneText: "네",
    });
  };

  doExpire = async () => {
    let user: UserInfo = {
      id: this.props.user.id,
    };

    log(LogLevel.UI_ACTION, "UserManageProfile doExpire", user);
    let result = await fetchAPI(
      API.USER_DELETE,
      "",
      user,
      null,
      getGlobal(GlobalKey.TOKEN)
    );
    if (result && !result.error) {
      this.props.onDone();
    } else {
      log(LogLevel.UI_EXCEPTION, "UserManageProfile Update failed", result);
    }
  };

  onErrorUpload = () => {
    this.setState({
      adminMessage:
        "첨부파일 오류로 면허증 이미지가 보이지 않아 인증이 어렵습니다.\n성함, 면허번호를 확인가능한 약사면허증 사진을 첨부하여 재신청하시거나\n카톡문의로 전달 부탁드립니다.",
    });
  };

  onErrorImage = () => {
    this.setState({
      adminMessage:
        "약사인증을 위하여 성함, 면허번호를 확인가능한 약사면허증 사진을 첨부하여 재신청 부탁드립니다.\n문의사항은 카톡문의로 연락주세요.",
    });
  };

  onErrorLicense = () => {
    this.setState({
      adminMessage:
        "첨부해주신 면허증사진에 면허번호가 인식되지 않습니다.\n약사인증을 위하여 성함, 면허번호를 확인가능한 약사면허증 사진을 첨부하여 재신청 부탁드립니다.\n문의사항은 카톡문의로 연락주세요.",
    });
  };

  fetchSocialIds = () => {
    fetchAPI(
      API.USER_GET_SNS,
      this.props.user.id.toString(),
      null,
      null,
      getGlobal(GlobalKey.TOKEN)
    ).then((result) => {
      if (result && !result.error && result.data) {
        this.setState({ socialIds: result.data });
      }
    });
  };

  onMergeSocialIds = () => {
    if (this.state.mergeIntoUser) {
      this.props.confirmPopup.show({
        title: (
          <div className="common-container">
            <span className="common-bold">{this.props.user.name}</span> 회원님의
            로그인소셜계정을 정말로{" "}
            <span className="common-bold common-color-caution">삭제</span>{" "}
            시키시겠습니까?
          </div>
        ),
        body: (
          <div className="common-container">
            로그인 정보다 해당 회원으로 모두 이동됩니다. 원복이 불가능합니다.
          </div>
        ),
        onDone: () => {
          fetchAPI(
            API.USER_SNS_MERGE,
            "",
            { from: this.props.user.id, to: this.state.mergeIntoUser.id },
            null,
            getGlobal(GlobalKey.TOKEN)
          ).then((result) => {
            this.fetchSocialIds();
          });
        },
        doneText: "네",
      });
    }
  };

  doAble = (type, id, current) => {
    fetchAPI(
      API.USER_SNS_ABLE,
      "",
      null,
      { userId: this.props.user.id, type, id, valid: !current },
      getGlobal(GlobalKey.TOKEN)
    ).then((result) => {
      this.fetchSocialIds();
    });
  };

  render() {
    console.log(this.props.user, this.state.level);
    if (this.props.user) {
      let licenseImg;
      if (this.props.user.licenseUrl)
        licenseImg = (
          <div className="common-container-column">
            <IonButton expand="full" onClick={this.onDeleteLicense}>
              면허사진 삭제
            </IonButton>
            <img
              className="user-manage-image"
              src={StringUtil.convertFilePath(this.props.user.licenseUrl)}
            />
          </div>
        );

      let deleteProfile = (
        <IonButton
          color="user-manage-button-critical"
          onClick={this.onDeleteProfile}
        >
          프로필삭제
        </IonButton>
      );

      let buttonsForAlive;
      if (!this.props.user.expiredAt) {
        buttonsForAlive = (
          <div className="user-manage-button-container">
            {this.props.user.workType == UserWorkType.UNDERGRADUATE &&
              this.props.user.state == UserState.LICENSE_CHECK && (
                <IonButton
                  color="user-manage-button"
                  onClick={this.onLicenseCheck}
                >
                  대학생 약사인증
                </IonButton>
              )}
            {this.props.user.workType == UserWorkType.UNDERGRADUATE &&
              this.props.user.state == UserState.LICENSE_CHECK && (
                <IonButton
                  color="user-manage-button-critical"
                  onClick={this.onLicenseDeny}
                >
                  대학생 약사인증 반려
                </IonButton>
              )}
            <div className="user-manage-button-container" />
            {deleteProfile}
            <div className="user-manage-button-container" />
            <IonButton
              color="user-manage-button-critical"
              onClick={this.onExpire}
            >
              탈퇴
            </IonButton>
          </div>
        );
      }

      let route = [];
      if (this.props.user.registerRoute) {
        if (this.props.user.registerRoute.includes(RegisterRoute.KAKAOTALK))
          route.push(
            <div key="1" className="admin-toggle">
              {REGISTER_ROUTE_NAME[RegisterRoute.KAKAOTALK]}
            </div>
          );
        if (this.props.user.registerRoute.includes(RegisterRoute.EXPO))
          route.push(
            <div key="2" className="admin-toggle">
              {REGISTER_ROUTE_NAME[RegisterRoute.EXPO]}
            </div>
          );
        if (this.props.user.registerRoute.includes(RegisterRoute.NEWS))
          route.push(
            <div key="3" className="admin-toggle">
              {REGISTER_ROUTE_NAME[RegisterRoute.NEWS]}
            </div>
          );
        if (this.props.user.registerRoute.includes(RegisterRoute.CONTENT))
          route.push(
            <div key="4" className="admin-toggle">
              {REGISTER_ROUTE_NAME[RegisterRoute.CONTENT]}
            </div>
          );
        if (this.props.user.registerRoute.includes(RegisterRoute.INVITE))
          route.push(
            <div key="5" className="admin-toggle">
              {REGISTER_ROUTE_NAME[RegisterRoute.INVITE]}
            </div>
          );
        if (this.props.user.registerRoute.includes(RegisterRoute.FACEBOOK))
          route.push(
            <div key="6" className="admin-toggle">
              {REGISTER_ROUTE_NAME[RegisterRoute.FACEBOOK]}
            </div>
          );
        if (this.props.user.registerRoute.includes(RegisterRoute.YSGL))
          route.push(
            <div key="7" className="admin-toggle">
              {REGISTER_ROUTE_NAME[RegisterRoute.YSGL]}
            </div>
          );
        if (this.props.user.registerRoute.includes(RegisterRoute.DP_RECRUIT))
          route.push(
            <div key="8" className="admin-toggle">
              {REGISTER_ROUTE_NAME[RegisterRoute.DP_RECRUIT]}
            </div>
          );
        if (this.props.user.registerRoute.includes(RegisterRoute.BAROPHARM))
          route.push(
            <div key="9" className="admin-toggle">
              {REGISTER_ROUTE_NAME[RegisterRoute.BAROPHARM]}
            </div>
          );
        if (this.props.user.registerRoute.includes(RegisterRoute.ETC))
          route.push(
            <div key="10" className="admin-toggle">
              {this.props.user.registerRouteEtc}
            </div>
          );
      }

      let socialIdsElements = [];
      if (this.state.socialIds) {
        if (this.state.socialIds == 0) {
          socialIdsElements.push(<div key="1">로그인 소셜 계정 정보 없음</div>);
        } else {
          let name, button;
          if (this.state.mergeIntoUser) {
            name = (
              <div className="common-flex-grow">
                {this.state.mergeIntoUser.name}/
                {this.state.mergeIntoUser.license}
              </div>
            );
            button = (
              <IonButton
                color="user-manage-button"
                onClick={this.onMergeSocialIds}
              >
                통합
              </IonButton>
            );
          }

          let head = (
            <div
              key="-1"
              className="common-container-row common-flex-align-center"
            >
              <div className="user-manage-title">다음 ID로 통합:</div>
              <IonInput
                class="user-search-input"
                type="number"
                placeholder="ID"
                value={this.state.mergeIntoId.toString()}
                onIonChange={(e) => {
                  let id = parseInt(e.detail.value);
                  if (id > 0) {
                    this.setState({ mergeIntoId: id });
                    fetchAPI(
                      API.USER_GET,
                      "",
                      { id },
                      null,
                      getGlobal(GlobalKey.TOKEN)
                    ).then((result) => {
                      if (result && !result.error && result.data) {
                        this.setState({ mergeIntoUser: result.data });
                      } else {
                        log(
                          LogLevel.UI_EXCEPTION,
                          "UserManageProfile Update failed",
                          result
                        );
                      }
                    });
                  } else {
                    this.setState({ mergeIntoId: 0 });
                  }
                }}
              />
              {name}
              {button}
            </div>
          );

          let body = this.state.socialIds.map((item, index) => {
            return (
              <div
                key={index.toString()}
                className="common-container-row common-flex-align-center"
              >
                <div className="user-manage-title common-flex-grow">
                  [{item.type ? "A" : "K"}]{item.authId}(
                  {item.valid ? "활성" : "비활성"})
                </div>
                <div className="admin-toggle">
                  {item.createdAt
                    ? getDateStringFromToday({ date: item.createdAt })
                    : "-"}
                </div>
                <IonButton
                  color="user-manage-button"
                  onClick={() =>
                    this.doAble(item.type, item.authId, item.valid)
                  }
                >
                  {item.valid ? "비활성화" : "활성화"}
                </IonButton>
              </div>
            );
          });

          socialIdsElements = [head, ...body];
        }
      } else {
        socialIdsElements = [
          <IonButton key="0" expand="full" onClick={this.fetchSocialIds}>
            소셜계정불러오기
          </IonButton>,
        ];
      }

      let licenseGuessYear = UserUtil.guessUserLicensedAt(this.state.license);

      return (
        <div className="user-manage-container">
          {this.props.onCancel && (
            <IonHeader class="common-header" no-border>
              <IonToolbar color="common-header-toolbar">
                <IonButtons slot="start">
                  <IonButton
                    class="common-header-toolbar-button"
                    onClick={this.props.onCancel}
                  >
                    <IonIcon name="arrow-back" mode="ios" />
                  </IonButton>
                  <IonLabel
                    class="common-header-title"
                    onClick={this.props.onCancel}
                  >
                    사용자정보
                  </IonLabel>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
          )}
          <div className="user-manage-level-container">
            <div className="user-manage-title">권한</div>
            <IonSelect
              class="user-manage-level"
              value={this.state.level.toString()}
              onIonChange={this.onLevelChange}
            >
              <IonSelectOption value={UserLevel.WAITING_USER.toString()}>
                {UserLevelName[UserLevel.WAITING_USER]}
              </IonSelectOption>
              <IonSelectOption value={UserLevel.DENIED_USER.toString()}>
                {UserLevelName[UserLevel.DENIED_USER]}
              </IonSelectOption>
              {/* <IonSelectOption value={UserLevel.RESTRICTED_USER.toString()}>
                {UserLevelName[UserLevel.RESTRICTED_USER]}
              </IonSelectOption> */}
              <IonSelectOption value={UserLevel.NORMAL_USER.toString()}>
                {UserLevelName[UserLevel.NORMAL_USER]}
              </IonSelectOption>
              <IonSelectOption value={UserLevel.MANAGER.toString()}>
                {UserLevelName[UserLevel.MANAGER]}
              </IonSelectOption>
              <IonSelectOption value={UserLevel.ADMIN.toString()}>
                {UserLevelName[UserLevel.ADMIN]}
              </IonSelectOption>
            </IonSelect>
          </div>
          <div className="common-container-row common-flex-align-center">
            <div
              className={
                this.state.level == UserLevel.WAITING_USER
                  ? "register2-work-type-selected"
                  : "register2-work-type-unselected"
              }
              onClick={() => this.changeLevel(UserLevel.WAITING_USER)}
            >
              {UserLevelName[UserLevel.WAITING_USER]}
            </div>
            <div
              className={
                this.state.level == UserLevel.DENIED_USER
                  ? "register2-work-type-selected"
                  : "register2-work-type-unselected"
              }
              onClick={() => this.changeLevel(UserLevel.DENIED_USER)}
            >
              {UserLevelName[UserLevel.DENIED_USER]}
            </div>
            <div
              className={
                this.state.level == UserLevel.NORMAL_USER
                  ? "register2-work-type-selected"
                  : "register2-work-type-unselected"
              }
              onClick={() => this.changeLevel(UserLevel.NORMAL_USER)}
            >
              {UserLevelName[UserLevel.NORMAL_USER]}
            </div>
            <div
              className={
                this.state.level == UserLevel.MANAGER
                  ? "register2-work-type-selected"
                  : "register2-work-type-unselected"
              }
              onClick={() => this.changeLevel(UserLevel.MANAGER)}
            >
              {UserLevelName[UserLevel.MANAGER]}
            </div>
          </div>
          <div className="user-manage-title">메모</div>
          <IonTextarea
            class="user-manage-textarea"
            placeholder={this.props.user.memo || "고객관리 메모를 적어주세요."}
            value={this.state.memo}
            onIonChange={(e) =>
              this.setState({ memo: e.detail.value, edited: true })
            }
          />

          <div className="common-container-row-wrap common-flex-align-center">
            <div className="user-manage-title common-flex-grow">
              관리자 메시지
            </div>
            <IonButton color="user-manage-button" onClick={this.onErrorUpload}>
              업로드오류
            </IonButton>
            <IonButton color="user-manage-button" onClick={this.onErrorImage}>
              이미지오류
            </IonButton>
            <IonButton color="user-manage-button" onClick={this.onErrorLicense}>
              면허번호누락
            </IonButton>
          </div>
          <IonTextarea
            class="user-manage-textarea"
            placeholder={this.props.user.managerMessage}
            value={this.state.adminMessage}
            onIonChange={this.onMessageChange}
          />

          <div className="user-manage-button-container">
            <IonButton
              color={
                this.props.user.managerMessage == "이미 가입된 면허번호입니다."
                  ? "user-manage-button-critical"
                  : "user-manage-button"
              }
              onClick={this.onAccept}
            >
              승인
            </IonButton>
            <div className="user-manage-button-container" />
            <IonButton
              color="user-manage-cancel-button"
              onClick={this.props.onCancel}
            >
              취소
            </IonButton>
            <div className="user-manage-button-container" />
            <IonButton
              color={
                this.isEditable()
                  ? "user-manage-button"
                  : "user-manage-cancel-button"
              }
              onClick={this.onDone}
            >
              수정
            </IonButton>
            <div className="user-manage-button-container" />
            <IonButton
              color={
                this.isRejectable()
                  ? "user-manage-button"
                  : "user-manage-cancel-button"
              }
              onClick={this.onReject}
            >
              반려
            </IonButton>
          </div>
          {buttonsForAlive}
          <div className="user-manage-title">
            ID : {this.props.user.id}{" "}
            {this.props.user.premiumType ? (
              <img
                src="/images/sprout2.png"
                style={{
                  width: "12px",
                  height: "15px",
                }}
              />
            ) : (
              ""
            )}
          </div>
          <div className="common-container-row common-flex-align-center">
            <div className="user-manage-title">닉네임 : </div>
            <IonInput
              class="user-search-input"
              placeholder="이름"
              value={this.state.nickname}
              onIonChange={(e) =>
                this.setState({
                  nickname: this.state.nicknameChange
                    ? e.detail.value
                    : this.state.nickname,
                  edited: true,
                })
              }
            />
            <IonCheckbox
              checked={this.state.nicknameChange}
              onClick={() => {
                this.setState({ nicknameChange: !this.state.nicknameChange });
              }}
            />
            <div
              className={
                this.state.nicknameChange
                  ? "viewer-comment-composer-button"
                  : "viewer-comment-composer-button-disabled"
              }
              onClick={() => {
                this.setState({ nicknameChange: !this.state.nicknameChange });
              }}
            >
              {" "}
              닉네임수정{" "}
            </div>
          </div>
          <div className="common-container-row common-flex-align-center">
            <div className="user-manage-title">연락처 : </div>
            <IonInput
              class="user-search-input"
              placeholder="연락처"
              value={this.state.phone}
              onIonChange={(e) =>
                this.setState({
                  phone: this.state.phoneChange
                    ? e.detail.value
                    : this.state.phone,
                  edited: true,
                })
              }
            />
            <div style={{ marginLeft: "10px" }}>
              <IconButton
                size="M"
                variant="Outlined"
                color="Primary"
                iconName="Call"
                onClick={() => callPhone(this.state.phone)}
              />
            </div>
            <div style={{ marginLeft: "10px", marginRight: "10px" }}>
              <IconButton
                size="M"
                variant="Outlined"
                color="Primary"
                iconName="Comment"
                onClick={() => sendSMSMessage(this.state.phone)}
              />
            </div>
            <IonCheckbox
              checked={this.state.phoneChange}
              onClick={(e) => {
                this.setState({ phoneChange: !this.state.phoneChange });
              }}
            />
            <div
              className={
                this.state.phoneChange
                  ? "viewer-comment-composer-button"
                  : "viewer-comment-composer-button-disabled"
              }
              onClick={(e) => {
                this.setState({ phoneChange: !this.state.phoneChange });
              }}
            >
              {" "}
              연락처수정{" "}
            </div>
          </div>
          <div className="common-container-row common-flex-align-center">
            <div className="user-manage-title">이메일 : </div>
            <IonInput
              class="user-search-input"
              placeholder="이메일"
              value={this.state.email}
              onIonChange={(e) =>
                this.setState({
                  email: this.state.emailChange
                    ? e.detail.value
                    : this.state.email,
                  edited: true,
                })
              }
            />
            <IonCheckbox
              checked={this.state.emailChange}
              onClick={(e) => {
                this.setState({ emailChange: !this.state.emailChange });
              }}
            />
            <div
              className={
                this.state.emailChange
                  ? "viewer-comment-composer-button"
                  : "viewer-comment-composer-button-disabled"
              }
              onClick={(e) => {
                this.setState({ emailChange: !this.state.emailChange });
              }}
            >
              {" "}
              이메일수정{" "}
            </div>
          </div>
          {this.state.workTypeChange ? (
            <div className="common-container-row common-flex-align-center">
              <div className="user-manage-title">근무정보 : </div>
              <div className="common-flex-grow common-container-row common-flex-row-wrap common-flex-align-center">
                <div
                  className={
                    (this.state.workType == UserWorkType.PHARMACY_OWNER &&
                      "admin-toggle-selected") + " admin-toggle"
                  }
                  onClick={() => {
                    this.setState({
                      edited: true,
                      workType: UserWorkType.PHARMACY_OWNER,
                    });
                  }}
                >
                  {UserWorkTypeName[UserWorkType.PHARMACY_OWNER]}{" "}
                </div>
                <div
                  className={
                    (this.state.workType == UserWorkType.PHARMACY_EMPLOYED &&
                      "admin-toggle-selected") + " admin-toggle"
                  }
                  onClick={() => {
                    this.setState({
                      edited: true,
                      workType: UserWorkType.PHARMACY_EMPLOYED,
                    });
                  }}
                >
                  {UserWorkTypeName[UserWorkType.PHARMACY_EMPLOYED]}{" "}
                </div>
                <div
                  className={
                    (this.state.workType == UserWorkType.HOSPITAL &&
                      "admin-toggle-selected") + " admin-toggle"
                  }
                  onClick={() => {
                    this.setState({
                      edited: true,
                      workType: UserWorkType.HOSPITAL,
                    });
                  }}
                >
                  {UserWorkTypeName[UserWorkType.HOSPITAL]}{" "}
                </div>
                <div
                  className={
                    (this.state.workType ==
                      UserWorkType.PHARMACEUTICAL_COMPANY &&
                      "admin-toggle-selected") + " admin-toggle"
                  }
                  onClick={() => {
                    this.setState({
                      edited: true,
                      workType: UserWorkType.PHARMACEUTICAL_COMPANY,
                    });
                  }}
                >
                  {UserWorkTypeName[UserWorkType.PHARMACEUTICAL_COMPANY]}{" "}
                </div>
                <div
                  className={
                    (this.state.workType == UserWorkType.GOVERMENT &&
                      "admin-toggle-selected") + " admin-toggle"
                  }
                  onClick={() => {
                    this.setState({
                      edited: true,
                      workType: UserWorkType.GOVERMENT,
                    });
                  }}
                >
                  {UserWorkTypeName[UserWorkType.GOVERMENT]}{" "}
                </div>
                <div
                  className={
                    (this.state.workType == UserWorkType.DISTRIBUTOR &&
                      "admin-toggle-selected") + " admin-toggle"
                  }
                  onClick={() => {
                    this.setState({
                      edited: true,
                      workType: UserWorkType.DISTRIBUTOR,
                    });
                  }}
                >
                  {UserWorkTypeName[UserWorkType.DISTRIBUTOR]}{" "}
                </div>
                <div
                  className={
                    (this.state.workType == UserWorkType.LEAVE_OF_ABSENCE &&
                      "admin-toggle-selected") + " admin-toggle"
                  }
                  onClick={() => {
                    this.setState({
                      edited: true,
                      workType: UserWorkType.LEAVE_OF_ABSENCE,
                    });
                  }}
                >
                  {UserWorkTypeName[UserWorkType.LEAVE_OF_ABSENCE]}{" "}
                </div>
                <div
                  className={
                    (this.state.workType == UserWorkType.ETC &&
                      "admin-toggle-selected") + " admin-toggle"
                  }
                  onClick={() => {
                    this.setState({ edited: true, workType: UserWorkType.ETC });
                  }}
                >
                  {UserWorkTypeName[UserWorkType.ETC]}{" "}
                </div>
                <div
                  className={
                    (this.state.workType == UserWorkType.GRADUATE &&
                      "admin-toggle-selected") + " admin-toggle"
                  }
                  onClick={() => {
                    this.setState({
                      edited: true,
                      workType: UserWorkType.GRADUATE,
                    });
                  }}
                >
                  {UserWorkTypeName[UserWorkType.GRADUATE]}{" "}
                </div>
                <div
                  className={
                    (this.state.workType == UserWorkType.UNDERGRADUATE &&
                      "admin-toggle-selected") + " admin-toggle"
                  }
                  onClick={() => {
                    this.setState({
                      edited: true,
                      workType: UserWorkType.UNDERGRADUATE,
                    });
                  }}
                >
                  {UserWorkTypeName[UserWorkType.UNDERGRADUATE]}{" "}
                </div>
              </div>
              <IonCheckbox
                checked={this.state.workTypeChange}
                onClick={(e) => {
                  this.setState({
                    workTypeChange: !this.state.workTypeChange,
                    workType: this.props.user.workType,
                  });
                }}
              />
              <div
                className={
                  this.state.workTypeChange
                    ? "viewer-comment-composer-button"
                    : "viewer-comment-composer-button-disabled"
                }
                onClick={(e) => {
                  this.setState({
                    workTypeChange: !this.state.workTypeChange,
                    workType: this.props.user.workType,
                  });
                }}
              >
                {" "}
                근무유형수정{" "}
              </div>
            </div>
          ) : (
            <div className="common-container-row common-flex-align-center">
              <div className="user-manage-title common-flex-grow">
                근무정보 :{" "}
                <Text
                  textType={
                    this.props.user.workType == UserWorkType.UNDERGRADUATE
                      ? "Body2Bold"
                      : "Body2"
                  }
                  color={
                    this.props.user.workType == UserWorkType.UNDERGRADUATE
                      ? COLOR_SYSTEM.get("Blue")[400]
                      : COLOR_SYSTEM.get("Gray")[800]
                  }
                >
                  {UserWorkTypeName[this.props.user.workType]}
                </Text>
              </div>
              <IonCheckbox
                checked={this.state.workTypeChange}
                onClick={(e) => {
                  this.setState({ workTypeChange: !this.state.workTypeChange });
                }}
              />
              <div
                className={
                  this.state.workTypeChange
                    ? "viewer-comment-composer-button"
                    : "viewer-comment-composer-button-disabled"
                }
                onClick={(e) => {
                  this.setState({ workTypeChange: !this.state.workTypeChange });
                }}
              >
                {" "}
                근무유형수정{" "}
              </div>
            </div>
          )}
          <div className="user-manage-title">
            {this.props.user.workType == UserWorkType.UNDERGRADUATE
              ? "학교"
              : "근무지"}{" "}
            : {this.props.user.workPlaceRegion} {this.props.user.workPlaceName}{" "}
          </div>
          <div className="common-flex-row">
            <div className="user-manage-title">가입경로 : </div>
            {route}
          </div>
          <div className="common-container-row common-flex-align-center">
            <div className="user-manage-title">이름 : </div>
            <IonInput
              class="user-search-input"
              placeholder="이름"
              value={this.state.name}
              onIonChange={(e) =>
                this.setState({
                  name: this.state.nameChange
                    ? e.detail.value
                    : this.state.name,
                  edited: true,
                })
              }
            />
            <IonCheckbox
              checked={this.state.nameChange}
              onClick={(e) => {
                this.setState({ nameChange: !this.state.nameChange });
              }}
            />
            <div
              className={
                this.state.nameChange
                  ? "viewer-comment-composer-button"
                  : "viewer-comment-composer-button-disabled"
              }
              onClick={(e) => {
                this.setState({ nameChange: !this.state.nameChange });
              }}
            >
              {" "}
              이름수정{" "}
            </div>
          </div>
          <div className="common-container-row common-flex-align-center">
            <div className="user-manage-title">
              {this.props.user.workType == UserWorkType.UNDERGRADUATE
                ? "학번"
                : "면허번호"}{" "}
              :
            </div>
            <IonInput
              class="user-search-input"
              placeholder="학번"
              value={this.state.license}
              onIonChange={(e) =>
                this.setState({ license: e.detail.value, edited: true })
              }
            />
          </div>
          <div className="common-container-row common-flex-align-center">
            <div className="user-manage-title">생년/성별 :</div>
            <IonInput
              class="user-search-input"
              placeholder="생년성별"
              value={this.state.birthGender}
              onIonChange={(e) =>
                this.setState({ birthGender: e.detail.value, edited: true })
              }
            />
          </div>
          <div className="common-container-row common-flex-align-center">
            <div className="user-manage-title">생일 :</div>
            <IonInput
              class="user-search-input"
              placeholder="생일"
              value={this.state.birthday}
              onIonChange={(e) =>
                this.setState({ birthday: e.detail.value, edited: true })
              }
            />
          </div>
          <div className="common-container-row common-flex-align-center">
            <div className="user-manage-title">성별 : {this.state.gender}</div>
            <div
              className={
                "admin-toggle" +
                (this.state.gender == "1" ? " admin-toggle-selected" : "")
              }
              onClick={() => {
                this.setState({ edited: true, gender: "1" });
              }}
            >
              남
            </div>
            <div
              className={
                "admin-toggle" +
                (this.state.gender == "2" ? " admin-toggle-selected" : "")
              }
              onClick={() => {
                this.setState({ edited: true, gender: "2" });
              }}
            >
              여
            </div>
          </div>
          <div className="common-container-row common-flex-align-center">
            <div className="user-manage-title">면허취득년 :</div>
            <IonInput
              class="user-search-input"
              placeholder="면허취득년"
              value={this.state.licensedAt}
              disabled={
                this.props.user.workType == UserWorkType.UNDERGRADUATE &&
                this.props.user.state != UserState.LICENSE_CHECK
              }
              onIonChange={(e) =>
                this.setState({ licensedAt: e.detail.value, edited: true })
              }
            />
            {(this.props.user.workType != UserWorkType.UNDERGRADUATE ||
              this.props.user.state == UserState.LICENSE_CHECK) &&
              this.state.licensedAt.includes("*") && (
                <IonButton
                  color="user-manage-tight-button"
                  onClick={() => {
                    this.setState({
                      licensedAt: this.state.licensedAt.replace("*", ""),
                    });
                  }}
                >
                  확정
                </IonButton>
              )}
            {(this.props.user.workType != UserWorkType.UNDERGRADUATE ||
              this.props.user.state == UserState.LICENSE_CHECK) &&
              this.state.licensedAt.includes("*") &&
              !this.state.licensedAt.startsWith(licenseGuessYear) && (
                <div
                  className={"user-manage-title"}
                  onClick={(e) =>
                    this.setState({
                      licensedAt: licenseGuessYear,
                      edited: true,
                    })
                  }
                >
                  {licenseGuessYear}
                </div>
              )}
          </div>
          <div className="user-manage-title">
            가입일 : {getDateTimeString(this.props.user.createdAt)}
          </div>

          <div className="user-manage-description">
            {this.props.user.description}
          </div>
          {licenseImg}

          {socialIdsElements}
          <div className="common-container-row common-flex-align-center">
            <div className="user-manage-title common-flex-grow">
              근무유형 변경일 :{" "}
              {getDateTimeString(this.props.user.workTypeEditedAt)}
            </div>
            {this.props.user.workTypeEditedAt && (
              <IonButton
                color="user-manage-button"
                onClick={() => this.onClearworkTypeEditedAt()}
              >
                초기화
              </IonButton>
            )}
          </div>
          <div className="user-manage-title">
            마케팅동의일 : {getDateTimeString(this.props.user.agreeMarketingAt)}
          </div>
          <div className="user-manage-title">
            평생회원 : {UserPermanentType[this.props.user.permenent]}
          </div>
          <div className="user-manage-title">
            약관동의버전 : {this.props.user.contractVersion}
          </div>
          <div className="user-manage-title">
            구인알림설정 : {JobOfferName[this.props.user.jobpostStatus]}
          </div>
          <div className="user-manage-title">
            구인알림지역 : {this.props.user.homeRegion}
          </div>
          <div className="user-manage-title">
            구인알림거리 : {this.props.user.jobpostDistance}
          </div>
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = (state: RootState) => ({
  // filePath: state.board.filePath,
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  me: state.user.me,
});

const mapDispatchToProps = {
  //   logOutUser: () => actions.user.logout(),
  //   refreshBoards: () => actions.board.refreshBoards(),
};
export default connect(mapStateToProps, mapDispatchToProps)(UserManageProfile);
