import React from "react";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.snow.css";
import styled from "styled-components";
import { COLOR_SYSTEM } from "../design/design-system";
import Text from "./Text";
import ReactQuill, { Quill } from "react-quill";

interface WysiwygEditorStyledProps {
  subTitle?: string;
  error?: boolean;
}

const WysiwygEditorLayout = styled.div<WysiwygEditorStyledProps>`
  .subtitle {
    display: block;
    margin-bottom: 8px;
  }

  .ql-toolbar {
    border-radius: 12px 12px 0 0;
    border: ${({ error }) =>
      `1px solid ${
        error ? COLOR_SYSTEM.get("Red")[100] : COLOR_SYSTEM.get("Gray")[50]
      }`};
  }

  .ql-container {
    border-radius: 0 0 12px 12px;
    background-color: ${({ error }) =>
      error ? COLOR_SYSTEM.get("Red")[10] : COLOR_SYSTEM.get("Gray")[10]};
    border: ${({ error }) =>
      `1px solid ${
        error ? COLOR_SYSTEM.get("Red")[100] : COLOR_SYSTEM.get("Gray")[50]
      }`};
    font-size: 16px;
  }

  .ql-editor {
    height: 370px;

    ul,
    ol {
      padding-left: 0;
    }

    &::before {
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      line-height: 24px;
      letter-spacing: -0.02rem;
      color: ${COLOR_SYSTEM.get("Gray")[300]};
    }
  }

  .editor__container {
    height: 412px;
  }
`;

const WysiwygEditorTitleBox = styled.div<WysiwygEditorStyledProps>`
  margin-bottom: ${({ subTitle }) => (subTitle ? "0px" : "8px")};
  display: flex;
  gap: 4px;

  .required {
    line-height: 21px;
  }
`;

interface WysiwygEditorProps {
  title?: string;
  required?: boolean;
  subTitle?: string;
  name: any;
  value?: string;
  placeholder?: string;
  error?: any;
  onChange: (name: any, value: string) => void;
}

const WysiwygEditor: React.FC<WysiwygEditorProps> = ({
  title,
  required = false,
  subTitle,
  name,
  value,
  placeholder,
  error,
  onChange,
}) => {
  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      [{ align: [] }, { color: [] }, { background: [] }],
    ],
  };

  const formats = [
    "font",
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "color",
    "background",
  ];

  return (
    <WysiwygEditorLayout error={error}>
      {title && (
        <WysiwygEditorTitleBox subTitle={subTitle}>
          <Text
            textType="InputMedium"
            color={
              error
                ? COLOR_SYSTEM.get("Red")[400]
                : COLOR_SYSTEM.get("Gray")[600]
            }
          >
            {title}
          </Text>
          {required && (
            <Text
              textType="InputSmall"
              color={COLOR_SYSTEM.get("Red")[300]}
              className="required"
            >
              *
            </Text>
          )}
        </WysiwygEditorTitleBox>
      )}
      {subTitle && (
        <Text
          textType="InputSmall"
          color={COLOR_SYSTEM.get("Gray")[400]}
          className="subtitle"
        >
          {subTitle}
        </Text>
      )}
      <div className="editor__container">
        <ReactQuill
          theme="snow"
          modules={modules}
          formats={formats}
          value={value}
          placeholder={placeholder}
          onChange={(content, delta, source, editor) => {
            onChange(name, editor.getHTML());
          }}
        />
      </div>
    </WysiwygEditorLayout>
  );
};

export default WysiwygEditor;
